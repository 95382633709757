import React, { useCallback } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { readConditions } from '../../../../OnBoarding/common/Form/util/form.util';
import { getValidationErrors } from '../../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';
import { PayrollStatus } from '../../../../Payroll/Constant/Constant';
import { Box, Grid, Typography } from '@mui/material';
import ActionHeader from '../../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import FormView from '../../../../OnBoarding/common/Form/FormView';
import ApprovalWorkflowAuditLog from '../../../../OnBoarding/Organization/ApprovalFlow/ApprovalWorkflowUtilsCommon/ApprovalWorkflowAuditTrail';
import ApprovalWorkflowFooterCommon from '../../../../OnBoarding/Organization/ApprovalFlow/Footer/ApprovalWorkflowFooterCommon';
import { GeoTagging } from '../../../../../services/geoTagging';

const LeaveViewForm = (props) => {
  const {
    setViewMode,
    claimFormData,
    formConfig,
    claimName,
    moduleId,
    auditTrail,
    claimId,
    claimTypeId,
    allowEdit,
    permissions,
    isEditable,
    setIsEditable,
    setValidationErrors,
    isMultiDepartment,
    benefitId,
    paymentModeId,
    approvalRemarksPermission,
  } = props;
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const { publicHoliday } = useSelector((state) => state && state.onBoarding);
  const defaultAduitLogTimezone = logoImage?.defaultAduitLogTimezone;
  const [isAuthorized, setisAuthorized] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuditTrail = auditTrail && auditTrail.length;

  const requiredFields = useMemo(() => {
    return formConfig.filter((item) => {
      let temp = item;
      const { conditional } = temp;
      if (conditional) {
        const { rules, action } = conditional;
        const isValid = rules.some((rule) => {
          const { conjunction, conditions } = rule;
          if (
            readConditions(
              conditions,
              conjunction,
              claimFormData,
              publicHoliday
            )
          ) {
            return true;
          }
        });
        if (action)
          temp = {
            ...temp,
            [action]: !isValid,
          };
      }
      return temp.isRequired && !temp.isHidden;
    });
  }, [formConfig, claimFormData]);

  const beforeApprovalValidations = () => {
    if (!allowEdit) return true;
    const errors = getValidationErrors(claimFormData || {}, requiredFields);
    if (isEmpty(errors)) {
      return true;
    }
    setViewMode(false);
    setValidationErrors(errors);
    return false;
  };

  const saveDetails = useCallback(async (action, data) => {
    switch (action) {
      case PayrollStatus.Approved: {
        new GeoTagging().statusUpdate({
          action: action === PayrollStatus.Approved ? 'APPROVED' : 'REJECTED',
          id: claimId,
        });
        break;
      }
      case PayrollStatus.Rejected: {
        new GeoTagging().statusUpdate({
          action: action === PayrollStatus.Approved ? 'APPROVED' : 'REJECTED',
          id: claimId,
        });
        break;
      }
      case PayrollStatus.SendBack: {
        new GeoTagging().statusUpdate({
          action: 'SENT BACK',
          id: claimId,
        });
        break;
      }
      case PayrollStatus.Pending: {
        new GeoTagging().statusUpdate({
          action: 'PENDING',
          id: claimId,
        });
        break;
      }
    }
  }, []);

  const isLastApproval = (data) => {
    if (
      data &&
      data.length > 2 &&
      data[data.length - 2].status === 'APPROVED'
    ) {
      return true;
    } else if (
      data &&
      data.length === 2 &&
      data[data.length - 2].status === 'CREATED'
    ) {
      return true;
    }
    return false;
  };

  const revertEnitilements = async (status) => {
      switch (status) {
        case PayrollStatus.Approved: {
          const geoTagging = new GeoTagging();
          await geoTagging.leaveStatusOnApprove(
            {leaveIds: [Number(claimFormData?.id)]}
          );
          break;
        }
      }
    };

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            labelText={claimName || 'New Form'}
            editButtonText="Edit"
            onEditClick={() => setViewMode(false)}
            showSave={isEditable || false}
            showBackButton
            onBackClick={() => navigate(-1)}
            labelCode={claimFormData.referenceNumber || ''}
          />
        </Box>
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item md={isAuditTrail ? 8 : 12}>
            <FormView
              list={formConfig}
              info={claimFormData}
              publicHoliday={publicHoliday}
            />
          </Grid>
          {isAuditTrail && (
            <Grid item md={4}>
              <Box
                ml={6}
                pl={6}
                sx={{ borderLeft: 'solid 1px', borderColor: 'neutral.dark40' }}
                mb={3}
              >
                <Typography variant="subtitle2" color={'neutral.dark80'} mb={3}>
                  Audit Trail
                </Typography>
                <ApprovalWorkflowAuditLog
                  auditTrail={auditTrail}
                  defaultTimezone={defaultAduitLogTimezone}
                />
              </Box>
            </Grid>
          )}
        </Grid>
        {isAuditTrail && (
          <ApprovalWorkflowFooterCommon
            recordId={claimFormData?.id}
            moduleId={moduleId}
            auditLogs={auditTrail}
            reasons={['Overlapping','Workload','Insufficient Notice','Unqualified', 'Others']}
            handleStatusUpdate={revertEnitilements}
            refId={claimFormData?.referenceNumber}
            setIsEditable={setIsEditable}
            saveDetails={saveDetails}
            handleApprove={() => navigate(-1)}
            beforeApprovalValidations={beforeApprovalValidations}
            claimRaisingFor={undefined}
            //   dataForApproval={{
            //     amount: claimFormData?.amount,
            //     claimantName: claimFormData?.createdBy?.displayName,
            //     distance: claimFormData?.distance,
            //     computedAmount: claimFormData?.computedAmount,
            //   }}
            isAuthorized={isAuthorized}
            setisAuthorized={setisAuthorized}
            approvalRemarksPermission={approvalRemarksPermission}
            paymentDate={false}
          />
        )}
      </Box>
    </Box>
  );
};

export default LeaveViewForm;
