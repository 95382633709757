import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import { GET_ALL_TIME_MANAGEMENT_FAILURE, GET_ALL_TIME_MANAGEMENT_REQUEST, GET_ALL_TIME_MANAGEMENT_SUCCESS } from './actionTypes';

const initialState = {
  requestProcessing: {
    [APIRequestStatus.GET_ALL_TIME_MANAGEMENT]: LoadingStatus.Loading,
  },
  timeManagementList: [],
  timeIdPropertiesMap: null,
  payCycle: null
};

export const timeManagementReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_ALL_TIME_MANAGEMENT_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_TIME_MANAGEMENT]: LoadingStatus.Loading
        }
      };
    }

    case GET_ALL_TIME_MANAGEMENT_SUCCESS: {
      const {list, isPayCycleEnded, gracePeriodEndDate} = action.payload;
      let timeIdPropertiesMap = {};
      list?.forEach((item) => {
        Object.assign(timeIdPropertiesMap, {
          [item.id]: {
            empStatusCode: item.fkEmployeeStatusCode,
            mgrStatusCode: item.fkManagerStatusCode,
            hrStatusCode: item.fkFinalStatusCode
          }
        });
      });
      return {
        ...state,
        timeManagementList: list,
        payCycle: {isPayCycleEnded, gracePeriodEndDate},
        timeIdPropertiesMap:timeIdPropertiesMap,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_TIME_MANAGEMENT]: LoadingStatus.Success
        }
      };
    }

    case GET_ALL_TIME_MANAGEMENT_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_TIME_MANAGEMENT]: LoadingStatus.Failure
        }
      };
    }

    default: {
      return state;
    }
  }
};

