import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormFieldTypes, LoadingStatus, ToastThemes } from '../../../../../constants/common';
import { showToast } from '../../../../../utils/common.util';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import PaycycleFormEdit from './paycycle-form/paycycle-form-edit';
import PaycycleFormView from './paycycle-form/paycycle-form-view';
import { TimeManagementServices } from '../../../../../services/timeManagementServices';

const AddPaycycle = (props) => {
  const { viewMode, setViewMode, payCycleId, setShowForm, setPayCycleId } =
    props;
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(-1);

  useEffect(async () => {
    if (currentUserRoleId) {
      if (payCycleId) {
        setLoading(LoadingStatus.Loading);
        await new TimeManagementServices()
          .getPayCycleDetail(payCycleId)
          .then((res) => {
            setFormData(res);
            setLoading(LoadingStatus.Success);
            setViewMode(true);
          })
          .catch((err) => {
            showToast(err?.response?.data?.message, ToastThemes.error);
            setLoading(LoadingStatus.Failure);
            throw err?.response?.data?.message;
          });
      }
    }
    return () => {
      setViewMode(false);
    };
  }, [payCycleId, currentUserRoleId]);

  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading && payCycleId} />
      {loading === LoadingStatus.Success && viewMode ? (
        <PaycycleFormView
          formConfig={fieldsForClaimCategories}
          setViewMode={setViewMode}
          isNew={!payCycleId}
          formData={formData}
          setShowForm={setShowForm}
        />
      ) : (
        <PaycycleFormEdit
          formConfig={fieldsForClaimCategories}
          setViewMode={setViewMode}
          formData={formData}
          isNew={!payCycleId}
          setShowForm={setShowForm}
          payCycleId={payCycleId}
          setPayCycleId={setPayCycleId}
        />
      )}
    </>
  );
};

export default AddPaycycle;

export const fieldsForClaimCategories = [
  // {
  //   id: 1,
  //   fieldLabel: 'Pay Frequency Code',
  //   placeholderText: 'Type Here',
  //   fieldType: FormFieldTypes.Text,
  //   isRequired: true,
  //   isHidden: false,
  //   fieldRefName: 'payFrequencyCode',
  //   size: 'small',
  //   fullWidth: true,
  //   fieldSize: 6,
  // },
  {
    id: 1,
    fieldLabel: 'Name',
    placeholderText: 'Type Here',
    fieldType: FormFieldTypes.Text,
    isRequired: true,
    isHidden: false,
    fieldRefName: 'name',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
  },
  {
    id: 1,
    fieldLabel: 'Pay Cycle Start Date',
    placeholderText: 'Type Here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    isRequired: true,
    isHidden: false,
    fieldRefName: 'payrollCycleStartDay',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
  },
  {
    id: 1,
    fieldLabel: 'Pay Cycle End Date',
    placeholderText: 'Type Here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    isRequired: true,
    isHidden: false,
    fieldRefName: 'payrollCycleEndDay',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
  },
  {
    id: 1,
    fieldLabel: 'Grace Days',
    placeholderText: 'Type Here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    isRequired: false,
    isHidden: false,
    fieldRefName: 'gracePeriodDays',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
  },
  {
    fieldLabel: 'Is Date For Same Month',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'datesForSameMonth',
  },
];
