import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import ListView from '../../../../atoms/DataList/ListView';
import { timeManagementHeaderCells } from '../Utils/TimeManagementUtils';
import ModalMapForTime from '../DropButtonActionButton/ModalMapForTime';
import { Button } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  APIRequestStatus,
  DebounceTimer,
  LoadingStatus,
  ToastThemes,
} from '../../../../constants/common';
import { getAllTimeManagement } from '../../../../redux/timeManagement/action';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import TryAgain from '../../../molecules/ErrorState/error.component';
import { DateRangePro } from '../../../molecules/DateRange/DateRangePro';
import {
  getDateTime,
  getFormattedDate,
  getFormattedStartOrEndDateForAnyPastMonthFromCurrent,
  getFormattedValues,
} from '../../../../utils/formatter';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import { EmptyDashboardMsg } from '../../Constant/Constant';
import { useDebounce } from 'usehooks-ts';
import { showToast } from '../../../../utils/common.util';

const AllEmployeeTimeManagement = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState([
    getFormattedStartOrEndDateForAnyPastMonthFromCurrent(0, 'month', true),
    getFormattedStartOrEndDateForAnyPastMonthFromCurrent(0, 'month', false),
  ]);

  const dispatch = useDispatch();
  const {
    timeManagementList,
    payCycle,
    requestProcessing: {
      [APIRequestStatus.GET_ALL_TIME_MANAGEMENT]: listLoading,
    },
  } = useSelector((state) => state.timeManagement);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [searchParams, setSearchParams] = useSearchParams();
  const empIdParams = searchParams.get('empId');
  const searchQuery = searchParams.get('q');
  const debouncedVal = useDebounce(searchQuery, DebounceTimer);

  const isDateInRange = getDateTime(
    payCycle?.gracePeriodEndDate,
    'YYYY-MM-DD'
  ).isBetween(selectedDate[0], selectedDate[1], null, '[]'); // The '[]' means inclusive range

  useEffect(() => {
    if (currentUserRoleId) {
      dispatch(
        getAllTimeManagement(
          currentUserRoleId,
          searchQuery,
          getFormattedValues('YYYYMM', selectedDate[0]),
          getFormattedValues('YYYYMM', selectedDate[1]),
          empIdParams
        )
      );
    }
  }, [currentUserRoleId, selectedDate, empIdParams, debouncedVal]);

  useEffect(() => {
    setCheckedItems([]);
  }, [timeManagementList]);

  useEffect(() => {
    if (
      payCycle && payCycle?.isPayCycleEnded
      && timeManagementList?.[0]?.fkFinalStatusCode != 'Approved' &&
      timeManagementList?.[0]?.fkEmployeeStatusCode != 'Approved'
      && isDateInRange
    ) {
      const showReminder = () => {
        showToast(
          `Reminder: Your pay cycle has been completed. Please ensure your timesheet for the month is submitted before ${payCycle?.gracePeriodEndDate}.`,
          ToastThemes.info
        );
      };

      showReminder();
      const intervalId = setInterval(() => {
        showReminder();
      }, 600000); // 1800000 ms = 30 minutes

      return () => clearInterval(intervalId);
    }
  }, [payCycle?.isPayCycleEnded]);

  const handleClear = (event) => {
    event.stopPropagation();
    searchParams.delete('empId');
    setSearchParams(searchParams);
  };

  const resetFilters = () => {
    setSelectedDate([
      getFormattedStartOrEndDateForAnyPastMonthFromCurrent(0, 'month', true),
      getFormattedStartOrEndDateForAnyPastMonthFromCurrent(0, 'month', false),
    ]);
    searchParams.delete('empId');
    setSearchParams(searchParams);
  };

  const isDisabledReset = () => {
    return (
      !empIdParams &&
      selectedDate[0].isSame(
        getFormattedStartOrEndDateForAnyPastMonthFromCurrent(0, 'month', true)
      ) &&
      selectedDate[1].isSame(
        getFormattedStartOrEndDateForAnyPastMonthFromCurrent(0, 'month', false)
      )
    );
  };

  const rowData = timeManagementList?.map((items) => {
    const {
      id,
      period,
      employeeId,
      firstName,
      lastName,
      employeeCode,
      departmentName,
      otHours,
      leaveDays,
      totalWorkingDays,
      totalPayDays,
      fkEmployeeStatusCode,
      fkManagerStatusCode,
      fkFinalStatusCode,
      displayName,
      isPayCycleEnded,
      gracePeriodEndDate,
    } = items;
    return {
      empId: employeeCode,
      empName: (
        <Link
          to={{ search: `?empId=${employeeId}` }}
          style={{ textDecoration: 'none' }}
        >
          <Button variant="text" color="primary">
            {displayName}
          </Button>
        </Link>
      ),
      dpt: departmentName,
      month: getFormattedDate(period, 'YYYYMM', 'YYYY-MM'),
      twd: totalWorkingDays,
      tpd: totalPayDays || totalWorkingDays - leaveDays,
      leaves: leaveDays ?? 0,
      ot: otHours ?? 0,
      empstatus: fkEmployeeStatusCode || 'Pending',
      mgrstatus: fkManagerStatusCode || 'Pending',
      status: fkFinalStatusCode || 'Pending',
      id: {
        hide: true,
        value: {
          id,
          employeeId,
          period: Number(getFormattedDate(period, 'YYYYMM', 'YYYYMM')),
          isPayCycleEnded,
          gracePeriodEndDate,
        },
      },
    };
  });

  if (listLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={listLoading === LoadingStatus.Loading} />
      <Box sx={{ backgroundColor: 'white' }}>
        <Box display="flex" justifyContent="flex-end" gap={2} p={2}>
          <DateRangePro
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <Button
            startIcon={<SettingsBackupRestoreOutlinedIcon />}
            sx={{ textTransform: 'capitalize' }}
            disabled={isDisabledReset()}
            onClick={resetFilters}
          >
            Clear
          </Button>
          <Box>
            <ModalMapForTime
              checkedItems={checkedItems}
              searchQuery={searchQuery}
              currentUserRoleId={currentUserRoleId}
              selectedDate={selectedDate}
              empIdParams={empIdParams}
            />
          </Box>
        </Box>
        <ListView
          headCells={timeManagementHeaderCells(empIdParams, handleClear)}
          rowsData={rowData}
          showSerialNo={true}
          showCheckbox={true}
          setCheckedItems={setCheckedItems}
          checkedItems={checkedItems}
          emptyDashboardMsg={EmptyDashboardMsg}
          allowSelectAll={true}
        />
      </Box>
    </>
  );
};

export default AllEmployeeTimeManagement;
