import { Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { DateRangePro } from '../../../molecules/DateRange/DateRangePro';
import ModalMapForPayroll from '../DropButtonActionButton/ModalMapForPayroll';
import ListView from '../../../../atoms/DataList/ListView';
import { payrollHeaderCells } from '../Utils/PayrollUtils';
import { useDebounce } from 'usehooks-ts';
import {
  APIRequestStatus,
  DebounceTimer,
  LoadingStatus,
} from '../../../../constants/common';
import {
  getAllPayroll,
  setDateFilterPayroll,
} from '../../../../redux/payroll/action';
import TryAgain from '../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import {
  InfoOutlined,
  SettingsBackupRestoreOutlined,
} from '@mui/icons-material';
import {
  getFormattedDate,
  getFormattedStartOrEndDateForAnyPastMonthFromCurrent,
  getFormattedValues,
} from '../../../../utils/formatter';
import { PayrollPermissions, SalaryType } from '../../Constant/Constant';
import { permissionsObj } from '../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { AppLevelPermissions } from '../../../OnBoarding/Organization/constants/onboarding.constants';

const AllPayroll = () => {
  const { currentUserRoleId, appPermissions } = useSelector(
    (state) => state.main
  );
  const { payrollPermission } = useSelector((state) => state.modulePermissions);
  const { payrollList, requestProcessing, idStatusMap, dateFilter } =
    useSelector((state) => state.payroll) || {};
  const { [APIRequestStatus.GET_ALL_PAYROLL]: listLoading } =
    requestProcessing || {};

  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dateFilter || [
      getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', true),
      getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', false),
    ]
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const empIdParams = searchParams.get('empId');
  const searchQuery = searchParams?.get('q');
  const debouncedVal = useDebounce(searchQuery, DebounceTimer);

  useEffect(() => {
    if (currentUserRoleId && appPermissions && payrollPermission) {
      dispatch(
        getAllPayroll(
          currentUserRoleId,
          searchQuery,
          getFormattedValues('YYYYMM', selectedDate?.[0]),
          getFormattedValues('YYYYMM', selectedDate?.[1]),
          empIdParams,
          appPermissions?.[AppLevelPermissions.Payroll]?.id,
          payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.id
        )
      );
    }
  }, [
    currentUserRoleId,
    selectedDate,
    empIdParams,
    debouncedVal,
    appPermissions,
    payrollPermission,
  ]);

  useEffect(() => {
    setCheckedItems([]);
  }, [payrollList]);

  useEffect(() => {
    dispatch(setDateFilterPayroll(selectedDate));
  }, [selectedDate]);

  const handleClear = (event) => {
    event.stopPropagation();
    searchParams.delete('empId');
    setSearchParams(searchParams);
  };

  const resetFilters = () => {
    setSelectedDate([
      getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', true),
      getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', false),
    ]);
    searchParams.delete('empId');
    setSearchParams(searchParams);
  };

  const isDisabledReset = () => {
    return (
      !empIdParams &&
      selectedDate?.[0]?.isSame(
        getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', true)
      ) &&
      selectedDate?.[1]?.isSame(
        getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', false)
      )
    );
  };

  function formatCurrency(value) {
    return value.toLocaleString('en-SG', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } 

  const rowData = payrollList?.map((items) => {
    const {
      id,
      baseSalary,
      total_allowances_amt,
      total_deductions_amt,
      netPay,
      cpfEmployeeAmt,
      cpfEmployerAmt,
      fkSalaryTypeCode: { salaryTypeCode },
      prlStatusCode,
      timeManagement: {
        period,
        stdHrs,
        employee: {
          id: employeeId,
          firstName,
          lastName,
          displayName,
          reportingTo,
          department: { name },
          employeeId: employeeCode,
        },
      },
    } = items;
    const grossResult =
      Number(total_allowances_amt) +
      (salaryTypeCode === SalaryType.Hourly
        ? Number(baseSalary) * Number(stdHrs)
        : Number(baseSalary)) -
      Number(total_deductions_amt);
    const formattedPeriodVal = getFormattedDate(period, 'YYYYMM', 'YYYY-MM');
    return {
      empId: employeeCode,
      empName: (
        <Link
          to={{ search: `?empId=${employeeId}` }}
          style={{ textDecoration: 'none' }}
        >
          <Button variant="text" color="primary">
            {firstName + ' ' + lastName}
          </Button>
        </Link>
      ),
      month: formattedPeriodVal,
      dept: name,
      manager: reportingTo
        ? reportingTo?.displayName 
        : '',
      salary: formatCurrency(grossResult || 0),
      net: formatCurrency(Number(netPay) || 0),
      employerCPF: formatCurrency(cpfEmployerAmt || 0),
      empCPF: formatCurrency(cpfEmployeeAmt || 0),
      status: prlStatusCode?.name,
      approverAction: payrollPermission?.[
        PayrollPermissions.APPROVER_DASHBOARD
      ]?.[permissionsObj.allowView]
        ? idStatusMap[id]
        : { hide: true },
      action: (
        <IconButton onClick={() => navigate({ pathname: `${id}` })}>
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        hide: true,
        value: { id, refId: `${employeeCode} ${formattedPeriodVal}`, payMonth: period },
      },
    };
  });

  if (listLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  return (
    <>
      <CircularLoader show={listLoading === LoadingStatus.Loading} />
      <Box display="flex" justifyContent="flex-end" gap={2} mb={2} pr={2}>
        <DateRangePro
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <Button
          startIcon={<SettingsBackupRestoreOutlined />}
          sx={{ textTransform: 'capitalize' }}
          disabled={isDisabledReset()}
          onClick={resetFilters}
        >
          Clear
        </Button>
        <Box>
          <ModalMapForPayroll
            checkedItems={checkedItems}
            searchQuery={searchQuery}
            currentUserRoleId={currentUserRoleId}
            selectedDate={selectedDate}
            empIdParams={empIdParams}
            appModuleId={appPermissions?.[AppLevelPermissions.Payroll]?.id}
            moduleId={
              payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.id
            }
          />
        </Box>
      </Box>
      <ListView
        headCells={payrollHeaderCells(
          empIdParams,
          handleClear,
          payrollPermission?.[PayrollPermissions.APPROVER_DASHBOARD]?.[
            permissionsObj.allowView
          ]
        )}
        rowsData={rowData || []}
        showSerialNo={true}
        showCheckbox={true}
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
        allowSelectAll={true}
      />
    </>
  );
};

export default AllPayroll;
