import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import AllEmployeeTimeManagement from './DashBoard/AllEmployeeTimeManagement';
import SelfieAndLocationForm from '../AttendanceForm/SelfieAndLocation/SelfieAndLocationForm';
import TimeManagementTopHeader from '../TimeManagementTopHeader';
import NavTabBar from '../../../atoms/Tabs/nav-tab-bar';
import { useDispatch, useSelector } from 'react-redux';
import { timeManagementTab } from './Utils/TimeManagementUtils';
import { permissionsObj } from '../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import AttendanceFormDashboard from '../AttendanceForm/AttendanceFormDashboard';
import TryAgain from '../../molecules/ErrorState/error.component';
import AllLeaveDashboard from '../../leave-management/leave-dashboard/leave-dashboard';
import { AppLevelPermissions } from '../../OnBoarding/Organization/constants/onboarding.constants';
import { getAllFormsList } from '../../../redux/work-space-builder/action';

const TimeManagementDashBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const benefitTabId = pathNames[pathNames.length - 1];

  const { timeManagementPermission } = useSelector(
    (state) => state.modulePermissions
  );
  const { allDashboardList } =
    useSelector((state) => state.workSpaceBuilder) || {};

  const { isMobile } = useSelector((state) => state.main);

  useEffect(() => {
    dispatch(getAllFormsList(AppLevelPermissions.TimeManagement));
  }, []);

  const formBuilderTab = allDashboardList?.map((item) => {
    const { displayName, id, moduleName } = item;
    return {
      name: displayName,
      id: id,
      module: moduleName
    };
  });
  const allTab = [...timeManagementTab, ...formBuilderTab];
  const tabsData = allTab.map((item, index) => {
    const { id, module } = item;
    return {
      id: id,
      name: timeManagementPermission?.[module]?.displayName,
      path: `/time-management/${id}`,
      label: timeManagementPermission?.[module]?.displayName,
      title: timeManagementPermission?.[module]?.displayName,
      show:
        module === 'time-management'
          ? timeManagementPermission?.['access-all-time-management']?.[
              permissionsObj.allowView
            ] ||
            timeManagementPermission?.['my-attendence']?.[
              permissionsObj.allowView
            ] ||
            timeManagementPermission?.[module]?.[permissionsObj.allowView]
          : timeManagementPermission?.[module]?.[permissionsObj.allowView],
    };
  });

  const filteredTabsList = tabsData.filter((item) => item.show);

  useEffect(() => {
    if ((benefitTabId === ':id' || benefitTabId === 'undefined') && (timeManagementTab.length || formBuilderTab.length)) {
      navigate(`/time-management/${filteredTabsList[0]?.id}`);
    }
  }, [timeManagementTab, formBuilderTab]);

  const getDashboards = () => {
    {
      switch (benefitTabId) {
        case 'time': {
          return <AllEmployeeTimeManagement />;
        }
        case 'attendance': {
          return <AttendanceFormDashboard />;
        }
        default: {
          if (!isNaN(Number(benefitTabId))) {
            return <AllLeaveDashboard />;
          }
          return <TryAgain />;
        }
      }
    }
  };

  return (
    <>
      <Box mt={4}>
        <TimeManagementTopHeader />
        <NavTabBar list={filteredTabsList} searchWidth={150} tabWidth={150} searchTab = {!isMobile}/>
        {getDashboards()}
      </Box>
    </>
  );
};

export default TimeManagementDashBoard;
