import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { FormHeader } from '../../../common/formHeader';
import ListView from '../../../../../atoms/DataList/ListView';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingStatus, ToastThemes } from '../../../../../constants/common';
import { InfoOutlined } from '@mui/icons-material';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { searchQuery } from '../../../../../constants/constants';
import { payCycle } from '../../constants/onboarding.constants';
import { permissionsObj } from '../../RolesAndPermissions/constants/roles-permissions.constants';
import { showToast } from '../../../../../utils/common.util';
import AddPaycycle from '../paycycle-form/AddPaycycle';
import { TimeManagementServices } from '../../../../../services/timeManagementServices';
import { IconButton } from '@mui/material';

const AllPaycycle = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(-1);
  const [viewMode, setViewMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [payCycleId, setPayCycleId] = useState({});
  const [searchParams] = useSearchParams();
  const { currentUserRoleId } = useSelector((state) => state.main);
  const searchInput = searchParams.get(searchQuery);

  const { permissions } = useSelector((state) => state.onBoarding);

  useEffect(async () => {
    if (!showForm) {
      try {
        setLoading(LoadingStatus.Loading);
        const response = await new TimeManagementServices().getListAllPayCycle(
          currentUserRoleId
        );
        setData(response);
        setLoading(LoadingStatus.Success);
      } catch (err) {
        setLoading(LoadingStatus.Failure);
        showToast(err?.response?.data?.message, ToastThemes.error);
        throw err?.response?.data?.message;
      }
    }
  }, [showForm]);

  const onClickAddIcon = () => {
    setShowForm(true);
    setPayCycleId('');
    setViewMode(false);
  };

  const onDetailsClick = (id) => {
    setShowForm(true);
    setViewMode(true);
    setPayCycleId(id);
  };

  const rowData = data?.map((items) => {
    const {
      id,
      name,
      payrollCycleStartDay,
      payrollCycleEndDay,
      gracePeriodDays,
    } = items;
    return {
      name: name,
      start: payrollCycleStartDay,
      end: payrollCycleEndDay,
      grace: gracePeriodDays,
      action: !permissions?.[payCycle]?.[
        permissionsObj.allowEdit
      ] ? (
        { hide: true }
      ) : (
        <IconButton
          onClick={() => onDetailsClick(id)}
          disabled={
            !permissions?.[payCycle]?.[permissionsObj.allowEdit]
          }
        >
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        hide: true,
        value: id,
      },
    };
  });

  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      {showForm ? (
        <AddPaycycle
          viewMode={viewMode}
          setViewMode={setViewMode}
          payCycleId={payCycleId}
          setShowForm={setShowForm}
          setPayCycleId={setPayCycleId}
        />
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <FormHeader title="Pay Cycle" />
          </Box>
          <ListView
            headCells={[
              { id: 'name', label: 'Name' },
              { id: 'start', label: 'Start Day' },
              { id: 'end', label: 'End Day' },
              { id: 'grace', label: 'Grace Days' },
            ]}
            rowsData={rowData || []}
            showSerialNo={true}
            showCheckbox={false}
            showAddIcon={permissions?.[payCycle]?.[permissionsObj.allowAdd]}
            onAddClick={() => onClickAddIcon()}
          />
        </Box>
      )}
    </>
  );
};

export default AllPaycycle;
