import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import CurrencyFormatter from '../../../../../atoms/CurrencyFormater/CurrencyFormater';
import { LightTooltip } from '../../../../../atoms/Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';

const ComponentFormView = (props) => {
  const { formInfo, fontSize } = props;

  return (
    <Box marginTop={3} display="flex" justifyContent="space-between">
      <Box>
        <Typography>
          {formInfo?.name}
          {formInfo?.cpfWageClassificationCode && (
            <LightTooltip
              title={`${formInfo?.cpfWageClassificationCode}`}
              placement="top"
              arrow
              fontSize={fontSize}
            >
              <Box color="primary.main" sx={{ display: 'inline-block' }}>
                <InfoOutlined
                  sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                />
              </Box>
            </LightTooltip>
          )}
        </Typography>
      </Box>
      <Box>
        <Typography>
          <CurrencyFormatter value={formInfo?.value || 0} />
        </Typography>
      </Box>
    </Box>
  );
};

export default ComponentFormView;
