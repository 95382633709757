import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import { AppLevelPermissions } from '../OnBoarding/Organization/constants/onboarding.constants';
import { fetchTimeManagementPermissions } from '../../redux/modulePermissions/action';
import TryAgain from '../molecules/ErrorState/error.component';
import CircularLoader from '../../atoms/CircularLoader/circular-loader';

const TimeManagement = () => {
  const [viewMode, setViewMode] = useState(false);

  const { currentUserRoleId, appPermissions } = useSelector(
    (state) => state.main
  );
  const dispatch = useDispatch();
  const {
    requestProcessing: {
      [APIRequestStatus.FETCH_TIME_MANAGEMENT_PERMISSIONS]: permissionsLoading,
    },
  } = useSelector((state) => state.modulePermissions);

  useEffect(() => {
    if (appPermissions && currentUserRoleId) {
      if (appPermissions[AppLevelPermissions.TimeManagement]) {
        dispatch(
          fetchTimeManagementPermissions(
            currentUserRoleId,
            appPermissions[AppLevelPermissions.TimeManagement].id
          )
        );
      }
    }
  }, [currentUserRoleId, appPermissions]);

  if (permissionsLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <Box m={4} pl={4}>
      <CircularLoader show={permissionsLoading === LoadingStatus.Loading} />
      {permissionsLoading === LoadingStatus.Success && (
        <Outlet context={[viewMode, setViewMode]} />
      )}
    </Box>
  );
};

export default TimeManagement;
