import { permissionsObj } from '../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import {
  PayrollPermissions,
  giroFileDashboard,
  giroFileLabel,
  payroll,
  payrollDashboard,
  payrollLabel,
} from '../../Constant/Constant';

export const getPayrollTab = (payrollPermission) => {
  return [
    {
      name: payrollDashboard,
      label: payrollLabel,
      title: payrollLabel,
      hide: !(
        payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.[
          permissionsObj.allowView
        ] ||
        payrollPermission?.[PayrollPermissions.MY_PAYROLL]?.[
          permissionsObj.allowView
        ] ||
        payrollPermission?.[PayrollPermissions.PAYROLL_MANAGER]?.[
          permissionsObj.allowView
        ] ||
        payrollPermission?.[PayrollPermissions.APPROVER_DASHBOARD]?.[
          permissionsObj.allowView
        ]
      ),
    },
    {
      name: giroFileDashboard,
      label: giroFileLabel,
      title: giroFileLabel,
      hide: !payrollPermission?.[PayrollPermissions.ALLOW_GIRO_FILE]?.[
        permissionsObj.allowView
      ],
    },
  ];
};

export const payrollHeaderCells = (
  empId,
  handleClearParams,
  isApprover = false
) => {
  const headCells = [
    {
      id: 'empId',
      label: 'Employee Id',
    },
    {
      id: 'empName',
      label: 'Employee Name',
      showFilterIcon: empId ? true : false,
      clear: (event) => handleClearParams(event),
    },
    {
      id: 'month',
      label: 'Month',
    },
    {
      id: 'dept',
      label: 'Department',
    },
    {
      id: 'manager',
      label: 'Manager',
    },
    {
      id: 'salary',
      label: 'Gross Salary',
      numeric: true,
    },
    {
      id: 'net',
      label: 'Net Pay',
      numeric: true,
    },
    {
      id: 'employerCPF',
      label: 'Employer Contribution',
      numeric: true,
    },
    {
      id: 'empCPF',
      label: 'Employee Contribution',
      numeric: true,
    },
    {
      id: 'status',
      label: 'Status',
    },
  ];
  if (isApprover) {
    headCells.push({
      id: 'approverAction',
      label: 'Approver Action',
    });
  }
  return headCells;
};

export const GiroFileDashHeaders = [
  {
    id: 'payrollRunDate',
    label: 'Payroll Run Date',
  },
  {
    id: 'payMonth',
    label: 'Pay Month',
  },
  {
    id: 'employeeCount',
    label: 'Employee Count',
  },
  {
    id: 'payrollRunTypeCode',
    label: 'Payroll Status',
  },
  {
    id: 'bankFileDownload',
    label: 'Giro File Status',
  },
  {
    id: 'bankGiroFileDownloadedUtc',
    label: 'Download Timestamp',
  },
  {
    id: 'bankGiroFileDownloadedBy',
    label: 'Downloaded By',
  }
];
export const getAllowedTabsPayroll = (payrollPermission, name) => {
  switch (name) {
    case payrollDashboard: {
      return (
        payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.[
          permissionsObj.allowView
        ] ||
        payrollPermission?.[PayrollPermissions.MY_PAYROLL]?.[
          permissionsObj.allowView
        ] ||
        payrollPermission?.[PayrollPermissions.PAYROLL_MANAGER]?.[
          permissionsObj.allowView
        ] ||
        payrollPermission?.[PayrollPermissions.APPROVER_DASHBOARD]?.[
          permissionsObj.allowView
        ]
      );
    }
    case giroFileDashboard: {
      return payrollPermission?.[PayrollPermissions.ALLOW_GIRO_FILE]?.[
        permissionsObj.allowView
      ];
    }
    default: {
      break;
    }
  }
};
