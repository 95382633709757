import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormFieldTypes } from '../../../../../../constants/common';
import { getIcon } from '../../../../helper/helper-utlis';

const FieldPicker = (props) => {
  const {handleClick, height = '255px', list} = props; 
  // const { list,  } = useSelector((state) => state.formBuilder) || {};

  return (
    <>
      <Box
        border="1px solid #efefef"
        borderLeft='none'
        height={height}
        sx={{ overflowY: 'scroll' }}
      >
        {list.map((item, index) => {
          if (
            item.fieldType === FormFieldTypes.Formula ||
            item.dataType === FormFieldTypes.Number || 
            item.fieldType === FormFieldTypes.AutoPopulate ||
            item.fieldType === FormFieldTypes.ProRateField ||
            item.fieldType === FormFieldTypes.EntitlementAutoPopulate || 
            item.fieldType === FormFieldTypes.Time
          )
            return (
              <Box
                key={index}
                borderBottom="1px solid #efefef"
                display="flex"
                alignItems="center"
                onClick={() => handleClick(item)}
                padding='3px 10px 5px 12px'
                lineHeight='30px'
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(167, 236, 226, 0.3)',
                  },
                }}
              >
                {getIcon(item.dataType || item.fieldType, {
                  width: '24px',
                  height: '24px',
                  mr: '5px',
                  color: 'gray',
                })}
                {item.fieldLabel}
              </Box>
            );
        })}
      </Box>
    </>
  );
};

export default FieldPicker;
