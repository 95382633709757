import { Box, Divider } from '@mui/material';
import React from 'react';
import DynamicBreadcrumb from '../../atoms/Breadcrumbs/dynamicBreadcrumb';
import SubHeader from './SubHeader/SubHeader';
import { timeManagementTab } from './TimeManagementOutlet/Utils/TimeManagementUtils';
import { useSelector } from 'react-redux';
import { permissionsObj } from '../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';

const TimeManagementTopHeader = () => {
  const { timeManagementPermission } = useSelector(
    (state) => state.modulePermissions
  );

  const { allDashboardList } =
    useSelector((state) => state.workSpaceBuilder) || {};

  const formBuilderTab = allDashboardList?.map((item) => {
    const { displayName, id, moduleName } = item;
    return {
      name: displayName,
      id: id,
      module: moduleName
    };
  });
  const allTab = [...timeManagementTab, ...formBuilderTab];

  const tabsData = allTab.map((item, index) => {
    const { id, module } = item;
    return {
      id: id,
      name: timeManagementPermission?.[module]?.displayName,
      path: `/time-management/${id}`,
      label: timeManagementPermission?.[module]?.displayName,
      title: timeManagementPermission?.[module]?.displayName,
      show:
        module === 'time-management'
          ? timeManagementPermission?.['access-all-time-management']?.[
              permissionsObj.allowView
            ] ||
            timeManagementPermission?.['my-attendence']?.[
              permissionsObj.allowView
            ] ||
            timeManagementPermission?.[module]?.[permissionsObj.allowView]
          : timeManagementPermission?.[module]?.[permissionsObj.allowView],
    };
  });

  const filteredTabsList = tabsData.filter((item) => item.show);

  return (
    <>
      <Box>
        <SubHeader tabsData={filteredTabsList} />
        <Box my={3}>
          <DynamicBreadcrumb />
          <Box mt={1}>
            <Divider />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TimeManagementTopHeader;
