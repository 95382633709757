import { Box, Typography, Button, Link } from '@mui/material';
import React, { useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ModalDialog from '../../../../atoms/Modal/ModalDialog';
import {
  ArrowBackOutlined,
  DeleteOutline,
  FileDownloadOutlined,
  FileUploadOutlined,
} from '@mui/icons-material';
import StyledButton from './ActionHeader.styled';
import { useSelector } from 'react-redux';

export default function ActionHeader(props) {
  const {
    labelText,
    onClickDiscard,
    onEditClick,
    showPublish,
    showSave = true,
    editButtonText,
    onPublishClick,
    showDiscard,
    disabledSave,
    disabledDiscard,
    publishButtonText = 'Publish',
    showPublishModal = true,
    labelCode,
    showBackButton,
    onBackClick,
    disabledPublish,
    showDownload,
    onDownloadClick,
    empId,
    discardButtonText = 'Discard',
    firstButton,
    secondButton,
    handleFirstButtonClick,
    handleSecondButtonClick,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');
  const [context, setContext] = useState('');

  // 🌟 Mobile-friendly: Detect if screen is small (e.g., phone)
    const { isMobile } = useSelector((state) => state.main);

  const onClickWShowModal = () => {
    setModalDesc('All unsaved changes will be lost.');
    setModalTitle('Discard Changes?');
    setShowModal(true);
    setContext('Discard');
  };

  const publishClickHandler = () => {
    if (showPublishModal) {
      setShowModal(true);
      setModalDesc(
        'Are you sure you want to publish changes to the module? These changes cannot be reversed.'
      );
      setModalTitle('Publish Changes?');
      setContext('Publish');
    } else {
      onPublishClick();
    }
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row', // 🌟 Stack on mobile
          justifyContent: 'space-between',
          alignItems: isMobile ? 'flex-start' : 'center', // 🌟 Left-align text on mobile
        }}
      >
        <Box
          border={labelCode ? 3 : 0}
          borderColor={labelCode ? 'primary.main40' : ''}
          paddingRight={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <Box sx={{ px: 3, py: '12px' }} bgcolor={'primary.main40'}>
            <Typography variant="body2" color={'neutral.dark'}>
              {labelText}
            </Typography>
          </Box>
          <Typography variant="body2Bold" color={'neutral.dark'} pl={2}>
            {labelCode}
          </Typography>
          <Typography variant="body2" color={'neutral.dark'} pl={2}>
            {empId}
          </Typography>
        </Box>
        <Box mt={isMobile ? 2 : 1} pr={isMobile ? 0 : 4}>
          {showDownload && (
            <StyledButton onClick={onDownloadClick}>
              <FileDownloadOutlined
                sx={{ width: '24px', height: '24px', mr: isMobile ? 0 : '5px' }}
              />
              Download
            </StyledButton>
          )}
          {firstButton && firstButton(handleFirstButtonClick)}
          {secondButton && secondButton(handleSecondButtonClick)}
          {showSave && (
            <StyledButton
              color="success"
              sx={{ mr: isMobile ? 0 : 1, textTransform: 'capitalize' }}
              onClick={onEditClick}
              disabled={disabledSave}
              className="ve-action-btn"
            >
              <EditOutlinedIcon
                sx={{ width: '24px', height: '24px', mr: isMobile ? 0 : '5px' }}
              />
              {editButtonText}
            </StyledButton>
          )}
          {showPublish && (
            <StyledButton
              onClick={publishClickHandler}
              disabled={disabledPublish}
            >
              <FileUploadOutlined
                sx={{ width: '24px', height: '24px', mr: isMobile ? 0 : '5px' }}
              />
              {publishButtonText}
            </StyledButton>
          )}
          {showDiscard && (
            <Button
              color="error"
              variant="outlined"
              sx={{
                borderColor: 'white',
                borderRadius: '20px',
                textTransform: 'capitalize',
                ml: isMobile ? 0 : 1, // 🌟 Adjust spacing
              }}
              onClick={disabledDiscard ? onClickWShowModal : onClickDiscard}
            >
              <DeleteOutline
                sx={{ width: '24px', height: '24px', mr: isMobile ? 0 : '5px' }}
              />
              {discardButtonText}
            </Button>
          )}
          {showBackButton && (
            <Button
              color="error"
              variant="outlined"
              sx={{
                borderColor: 'white',
                borderRadius: '20px',
                textTransform: 'capitalize',
                ml: isMobile ? 0 : 1, // 🌟 Adjust spacing
              }}
              onClick={onBackClick}
            >
              <ArrowBackOutlined
                sx={{ width: '24px', height: '24px', mr: isMobile ? 0 : '5px' }}
              />
              Back
            </Button>
          )}
        </Box>
      </Box>
      <ModalDialog
        title={modalTitle}
        description={modalDesc}
        primaryAction={context}
        secondaryAction="Cancel"
        showModal={showModal}
        onClickClose={() => setShowModal(false)}
        onClickConfirm={context === 'Publish' ? onPublishClick : onClickDiscard}
        severity={context === 'Publish' ? 'success' : 'error'}
      />
    </>
  );
}
