import React, { useEffect, useState } from 'react';
import SelectTag from '../../../../atoms/Select/SelectTag';
import {
  conditionInitialVal,
  ruleConjunctionOptions,
} from '../../utils/conditional.util';
import { Button, Grid, Paper, Typography, Box } from '@mui/material';
import AddConditions from './addConditions.component';
import { getFormBody } from '../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';
import { DeleteOutline } from '@mui/icons-material';

const Conditional = (props) => {
  const {
    conditionalFormState,
    storedConditions,
    setConditionalRules,
    index,
    noOfConditions,
    setOuterForm,
    list
  } = props;

  const [conditions, setConditions] = useState(
    storedConditions || [conditionInitialVal]
  );
  const [formState, setFormState] = useState(conditionalFormState || {});

  useEffect(() => {
    setConditionalRules((prev) => {
      const newData = [...prev];
      newData[index] = {
        ...formState,
        conditions: conditions.map((item) => getFormBody(item)),
      };
      return newData;
    });
  }, [conditions, formState]);

  const handleDropDown = (event) => {
    const { value, name } = event.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDelete = () => {
    if (noOfConditions === 1) {
      setFormState({});
      setOuterForm({});
      setConditions([conditionInitialVal]);
    } else {
      setConditionalRules((prev) => {
        const newConditions = [...prev];
        newConditions.splice(index, 1);
        const { conditions: storedConditions, ...rest } =
          newConditions[index] || {};
        setFormState(rest);
        setConditions(storedConditions);
        return newConditions;
      });
    }
  };

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={8}>
        <Grid item xs={12} md={12} display="flex">
          <SelectTag
            name="conjunction"
            dropDownList={ruleConjunctionOptions}
            size="small"
            fullWidth
            value={formState?.conjunction}
            onchange={handleDropDown}
          />
          <Typography display="flex" alignItems="center">
            &nbsp; of the conditions are met
          </Typography>
          <Box display="flex" alignItems="center"></Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h2" fontSize={18} pb={2}>
              Conditions
            </Typography>
            {conditions.map((item, index) => {
              return (
                <AddConditions
                  key={index}
                  index={index}
                  setConditions={setConditions}
                  condition={item}
                  noOfConditions={conditions.length}
                  setFormState={setFormState}
                  list={list}
                />
              );
            })}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} display="flex" justifyContent="flex-end">
          <Button
            color="error"
            variant="outlined"
            sx={{
              borderColor: 'white',
              borderRadius: '20px',
              textTransform: 'capitalize',
            }}
            onClick={handleDelete}
          >
            <DeleteOutline sx={{ width: '24px', height: '24px', mr: '5px' }} />
            Delete
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Conditional;
