import React, { useState } from 'react';
import { Box } from '@mui/material';
import AddConfig from './form-config/add-config.component';
import TabBar from '../../../atoms/Tabs/TabBar';
import Conditional from './conditional/conditional.component';
import DateValidation from './validation/date-validation.component';
import { FormFieldTypes } from '../../../constants/common';
import { conditionInitialVal } from '../utils/conditional.util';
import RuleConditions from './conditional-expression/rule-condition.component';
import FormApproval from './approvals/form-approval.component';
import FileUploadValidations from './validation/file-upload-validation.component';
import MultipleConditional from './conditional/multiple-condiation.component';
import NumberValidation from './validation/number-validation.component';
import DateConditional from './conditional-expression copy/date-condition.component';
import CurrencyConfig from './currency-config/currency-config.component';

const ManageTab = (props) => {
  const {
    formInfo,
    setFormInfo,
    fieldType,
    validationErrors,
    setValidationErrors,
    dataType,
    moduleId,
    list
  } = props;

  const { conditional, approverFieldValidation } = formInfo;
  const [approvalFormState, setApprovalFormState] = useState(
    approverFieldValidation || []
  );
  const [approvalConditions, setApprovalConditions] = useState(
    approverFieldValidation || [{}]
  );

  const formConfigTabs = [
    {
      name: 'add-config',
      label: 'Form Config',
      title: 'Form Config',
      hide: false,
      panel: (
        <AddConfig
          formInfo={formInfo}
          setFormInfo={setFormInfo}
          fieldType={fieldType}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          dataType={dataType}
          moduleId={moduleId}
          list={list}
        />
      ),
    },
    {
      name: 'conditional',
      label: 'Conditional',
      title: 'Conditional',
      hide: false,
      panel: (
        <MultipleConditional
          setFormInfo={setFormInfo}
          conditional={conditional}
          list={list}
        />
      ),
    },
    {
      name: 'approval',
      label: 'Approvals',
      title: 'Approvals',
      hide: false,
      panel: (
        <FormApproval
          setFormInfo={setFormInfo}
          formState={approvalFormState}
          setFormState={setApprovalFormState}
          conditions={approvalConditions}
          setConditions={setApprovalConditions}
        />
      ),
    },
    {
      name: 'validation',
      label: 'Validation',
      title: 'Validation',
      hide:
        fieldType !== FormFieldTypes.Date &&
        fieldType !== FormFieldTypes.DateTime &&
        fieldType !== FormFieldTypes.Month &&
        fieldType !== FormFieldTypes.Time &&
        fieldType !== FormFieldTypes.Year &&
        fieldType !== FormFieldTypes.DateRange,
      panel: (
        <DateValidation
          setFormInfo={setFormInfo}
          range={formInfo?.range}
          fieldType={fieldType}
          list={list}
        />
      ),
    },
    {
      name: 'conditional-expression',
      label: 'Conditional Expression',
      title: 'Conditional Expression',
      hide: fieldType !== FormFieldTypes.Formula,
      panel: (
        <RuleConditions
          setFormInfo={setFormInfo}
          computeExpression={formInfo?.computeExpression}
          formula={formInfo?.formula}
          list={list}
        />
      ),
    },
    {
      name: 'file-validation',
      label: 'Validation',
      title: 'Validation',
      hide: fieldType !== FormFieldTypes.MultiFileUpload && fieldType !== FormFieldTypes.File,
      panel: (
        <FileUploadValidations
          setFormInfo={setFormInfo}
          fileUploadValidations={formInfo?.fileUploadValidations}
        />
      ),
    },
    {
      name: 'validation',
      label: 'Validation',
      title: 'Validation',
      hide: dataType !== FormFieldTypes.Number,
      panel: (
        <NumberValidation setFormInfo={setFormInfo} range={formInfo?.range} list={list} />
      ),
    },
    {
      name: 'date-expression',
      label: 'Date Conditional',
      title: 'Date Conditional',
      hide: fieldType !== FormFieldTypes.Date,
      panel: (
        <DateConditional
          setFormInfo={setFormInfo}
          formula={formInfo?.dateConditional}
          list={list}
        />
      ),
    },
    {
      name: 'currency-config',
      label: 'Currency Config',
      title: 'Currency Config',
      hide: fieldType !== FormFieldTypes.CurrencyExchangeField,
      panel: (
        <CurrencyConfig mainFormInfo={formInfo} mainSetFormInfo={setFormInfo} list={list} />
      ),
    },
  ];

  return (
    <Box>
      <TabBar
        tabPanels={formConfigTabs.filter((item) => !item.hide)}
        disableCreate
      />
    </Box>
  );
};

export default ManageTab;
