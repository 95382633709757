import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import RoleConfigurationItem from './roleConfigurationItem';
import ClaimModalComponent from '../ClaimConfig/PermissionType/ClaimConfigModel/claim-modal.component';
import PerformanceConfigModal from '../performance-config/performance-config-modal.component';
import { RoleService } from '../../../../../services/roles';

export const SubModuleConfigure = (props) => {
  const {
    content,
    setModulePermissions,
    parentName,
    roleId,
    modulePermissions,
    moduleId,
    roleName,
  } = props;

  const [timePermissionComponent, setTimePermissionComponent] = useState(null);
  const [timeFilter, setTimeFilter] = useState([]);

  useEffect(() => {
    // Only fetch time permission when parentName is 'time-management'
    if (parentName === 'time-management') {
      const fetchTimePermission = async () => {
        const list = await new RoleService().getChildModules(moduleId);
        const filteredList = list
          .filter((role) => ![70, 74, 73, 69].includes(role.id))
          .map((role) => role.id);
        setTimeFilter(filteredList);
        setTimePermissionComponent(
          <ClaimModalComponent
            roleId={roleId}
            moduleId={moduleId}
            roleName={roleName}
            content={content}
            label="Time Config"
            claims={false}
            list={filteredList}
          />
        );
      };
      fetchTimePermission();
    }
  }, [parentName, moduleId, roleId, roleName, content]); // Add dependencies

  switch (parentName) {
    case 'claims-3.2': {
      return (
        <ClaimModalComponent
          roleId={roleId}
          moduleId={moduleId}
          roleName={roleName}
          content={content}
        />
      );
    }
    case 'performance-app': {
      return (
        <PerformanceConfigModal
          roleId={roleId}
          moduleId={moduleId}
          roleName={roleName}
          content={content}
        />
      );
    }
    default: {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2Bold">Module</Typography>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <Box
                    width={200}
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Typography variant="body2Bold">
                      Create, Edit And View Access
                    </Typography>
                  </Box>
                  <Box
                    width={200}
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Typography variant="body2Bold">
                      Edit And View Access
                    </Typography>
                  </Box>
                  <Box
                    width={200}
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Typography variant="body2Bold">View Access</Typography>
                  </Box>
                  <Box
                    width={200}
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Typography variant="body2Bold">Disabled</Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          {content?.map((item) => {
            const { name, id, permissions, displayName } = item;
            if (timeFilter.includes(id)) return null;
            return (
              <RoleConfigurationItem
                key={id}
                id={id}
                optionName={displayName}
                permissions={permissions}
                setModulePermissions={setModulePermissions}
                parentName={parentName}
                roleId={roleId}
                modulePermissions={modulePermissions}
                childName={name}
              />
            );
          })}
          {parentName === 'time-management' && timePermissionComponent}
        </Table>
      );
    }
  }
};
