import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CheckCircle,
  Assignment,
  EventAvailable,
} from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import './LandingPage.css';
import CardUI from '../../shared/components/ui-elements/CardUI';
import { useDispatch, useSelector } from 'react-redux';
import { AppLevelPermissions } from '../OnBoarding/Organization/constants/onboarding.constants';
import { fetchTimeManagementPermissions } from '../../redux/modulePermissions/action';
import { permissionsObj } from '../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { isEmpty } from 'lodash';

const MobileLandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUserRoleId, appPermissions, userInfo } = useSelector(
    (state) => state.main
  );
  const { timeManagementPermission } = useSelector(
    (state) => state.modulePermissions
  );

  const leaveEntries = Object.entries(timeManagementPermission || {})
    .filter(([key, value]) => key.includes('leave'))
    .map(([key, value]) => ({ key, id: value.id }));

  useEffect(() => {
    if (appPermissions && currentUserRoleId && isEmpty(timeManagementPermission)) {
      if (appPermissions[AppLevelPermissions.TimeManagement]) {
        dispatch(
          fetchTimeManagementPermissions(
            currentUserRoleId,
            appPermissions[AppLevelPermissions.TimeManagement].id
          )
        );
      }
    }
  }, [currentUserRoleId, appPermissions]);

  const options = [
    {
      name: 'Mark Attendance: In',
      icon: <CheckCircle fontSize="small" />,
      path: '/time-management/attendance/m?t=I',
      show:
        timeManagementPermission?.['attendance']?.[permissionsObj.allowAdd] ||
        false,
    },
    {
      name: 'Mark Attendance: OUT',
      icon: <CheckCircle fontSize="small" />,
      path: '/time-management/attendance/m?t=O',
      show:
        timeManagementPermission?.['attendance']?.[permissionsObj.allowAdd] ||
        false,
    },
    {
      name: 'Request for Leave',
      icon: <Assignment fontSize="small" />,
      path: `/time-management/${leaveEntries?.[0]?.id}/create`,
      show:  timeManagementPermission?.[leaveEntries?.[0]?.key]?.[permissionsObj.allowAdd] ||
      false,
    },
      {
        name: 'Approve Leave',
        icon: <EventAvailable fontSize="small" />,
        path: `/time-management/${leaveEntries?.[0]?.id}`,
        show:  timeManagementPermission?.[leaveEntries?.[0]?.key]?.viewAccess === 2 ||
      false,
      },
  ];

  const visibleOptions = options.filter(option => option.show);

  return (
    <Grid
      container
      className="main-wrap-bg"
      style={{ height: '100vh', padding: '2em' }}
    >
      <Grid item xs={12} sm={10} md={8}>
        <Typography variant="h4" className="user-name" gutterBottom>
          Hey <span> {userInfo?.displayName && userInfo.displayName}</span>,
        </Typography>
        <Typography variant="h5" className="user-name" gutterBottom marginBottom={5}>
          Welcome back!
        </Typography>
        <Grid container spacing={1} className="button-grid">
          {visibleOptions.map((option) => (
            <Grid item xs={12} key={option.path}>
              <Box
                className="option-card"
                onClick={() => navigate(option.path)}
                style={{ background: 'transparent' }}
              >
                <CardUI
                  background="rgba(255, 255, 255, 0.6)"
                  margin="5px"
                  gradient={true}
                >
                  <div
                    className="card-titles"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontWeight: 'bolder',
                      fontSize: 'larger'
                    }}
                  >
                    {option.icon} {option.name}
                  </div>
                </CardUI>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MobileLandingPage;
