import React, { useEffect } from 'react';
import PayrollAssignview from '../PayrollAssignview';
import ActionHeader from '../../../Organization/ActionHeader/ActionHeader';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getUpcomingComponentsDetails } from '../../../../../redux/payrollAssignment/action';
import { totalForComponents } from '../../../Organization/utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';

const UpcomingPayrollView = (props) => {
  const { formConfig, payStrInfo, userId, labelText } = props;
  const dispatch = useDispatch();
  const {
    upcomingPayStrData,
    upcomingSalaryType,
    upcomingAdditionComponents,
    upcomingDeductionComponents,
    upcomingEmployeeBenifits
  } = useSelector((state) => state.payrollAssignment);

  const totalAdditions = totalForComponents(upcomingAdditionComponents);
  const totalDeductions = totalForComponents(upcomingDeductionComponents);

  useEffect(() => {
    if (!isNaN(userId)) {   
      dispatch(getUpcomingComponentsDetails(payStrInfo?.id, userId));
    }
  }, [userId]);

  return (
    <>
          <Box mb={3} mx={-3} mt={-3}>
            <ActionHeader showSave={false} labelText={labelText} />
          </Box>
          <PayrollAssignview
            formFields={isEmpty(upcomingPayStrData) ? [] : formConfig}
            payStrInfo={upcomingPayStrData}
            basicComponents={upcomingSalaryType}
            additionComponents={upcomingAdditionComponents}
            deductionComponents={upcomingDeductionComponents}
            totalAdditions={totalAdditions}
            totalDeductions={totalDeductions}
            employeeBenifits={upcomingEmployeeBenifits}
          />
    </>
  );
};

export default UpcomingPayrollView;
