import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  FormFieldTypes,
  LoadingStatus,
  toastMessage,
  ToastThemes,
} from '../../../../../constants/common';
import { Box } from '@mui/material';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import FormEdit from '../../../../OnBoarding/common/Form/FormEdit';
import { BuilderServices } from '../../../../../services/builder';
import { getFormBody } from '../../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { showToast } from '../../../../../utils/common.util';
import {
  stringSubstitute,
  ToastMessages,
} from '../../../../OnBoarding/Organization/constants/onboarding.constants';
import { GeoTagging } from '../../../../../services/geoTagging';
import StyledButton from '../../../../OnBoarding/Organization/ActionHeader/ActionHeader.styled';
import { AddOutlined, ContentCopyOutlined } from '@mui/icons-material';

const LeaveEditForm = (props) => {
  const {
    formConfig,
    claimTypeData,
    claimFormData = {},
    setViewMode,
    claimTypeId,
    claimId,
    allowEdit,
    enitilements,
    resubmittedClaim,
    validationErrors,
    setValidationErrors,
  } = props;

  const { referenceNumber, fkCreatedBy, ...rest } = claimFormData || {};
  const [formState, setFormState] = useState(rest || {});
  const [dropDownOptions, setDropDownOptions] = useState({});
  const [loading, setLoading] = useState(-1);

  const { currentUserRoleId, userInfo, isMobile } = useSelector(
    (state) => state.main
  );
  const { publicHoliday } = useSelector((state) => state && state.onBoarding);
  //   const { entitlement } = useSelector((state) => state.formBuilder);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setFormState(rest);
  }, [claimFormData]);

  const getDropdownOptions = (name, id) => {
    switch (name) {
      default: {
        return dropDownOptions[name];
      }
    }
  };

  const handleDropdownClick = async (name, id) => {
    switch (name) {
      default: {
        return new BuilderServices().getDropDownOption(id).then((res) => {
          setDropDownOptions({ ...dropDownOptions, [name]: res });
          return true;
        });
      }
    }
  };

  const removeAutoPopulatedFields = () => {
    const updatedFormdata = { ...formState };
    formConfig.forEach((field) => {
      if (
        (field.fieldType === FormFieldTypes.AutoPopulate ||
          field.fieldType === FormFieldTypes.ProRateField) &&
        field.fieldRefName in updatedFormdata
      ) {
        delete updatedFormdata[field.fieldRefName];
      }
    });
    return updatedFormdata;
  };

  //   const checkForEntitlements = async (entitleMents, formState) => {
  //     let errors = '';
  //     let checkFlag = false;
  //     let dateError = '';
  //     if (!isEmpty(entitleMents)) {
  //       if ('amount' in formState) {
  //         if (Number(entitleMents.entitlementBalanceAmt) === 0) {
  //           errors = 'Entitlement Balance Amount is Zero';
  //         } else if ('receiptAmount' in formState) {
  //           errors = '';
  //         } else {
  //           checkFlag =
  //             Number(formState['amount']) <=
  //             Number(entitleMents.entitlementBalanceAmt);
  //           if (!checkFlag && Number(entitleMents.entitlementBalanceAmt)) {
  //             errors = 'Amount is greater than the balance amount';
  //           }
  //         }
  //       } else {
  //         errors =
  //           'Amount field needs to be configured in the form builder for Enitilements to work';
  //       }
  //       if (
  //         ('receiptDate' in formState || 'claimDate' in formState) &&
  //         !entitleMents.fkBenefitId?.isFixedEntitlement
  //       ) {
  //         const receiptDate = getDate(formState['receiptDate']);
  //         const claimDate = getDate(formState['claimDate']);
  //         if (
  //           isDateAfter(receiptDate, entitleMents.endDate) ||
  //           isDateAfter(claimDate, entitleMents.endDate)
  //         ) {
  //           dateError = 'Select Date On or Before Entitlement End Date';
  //         } else if (
  //           isDateBefore(receiptDate, entitleMents.startDate) ||
  //           isDateBefore(claimDate, entitleMents.startDate)
  //         ) {
  //           dateError = 'Select Date On or After Entitlement Start Date';
  //         }
  //         else {
  //           dateError = '';
  //         }
  //       }
  //     }
  //     return { entitleMentsErrors: errors, checkFlag: checkFlag, dateError };
  //   };

  const handleSaveClick = async () => {
    // const { entitleMentsErrors, checkFlag, dateError } =
    //   await checkForEntitlements(entitlement, formState);

    // if (entitleMentsErrors) {
    //   showToast(entitleMentsErrors, ToastThemes.error);
    //   return;
    // }

    const res = await postClaim();
    setViewMode(true);
    if (isMobile) {
      navigate('/home');
    } else {
      navigate(`/time-management/${claimTypeId}/${res.id}`, {
        replace: true,
      });
    }
  };

  const postClaim = async () => {
    try {
      const newFormData = removeAutoPopulatedFields();
      const formConfig = getFormBody({ ...newFormData, status: 'PENDING' });
      const { createdAt, ...claimConfigRest } = formConfig;
      setLoading(LoadingStatus.Loading);
      const payloadData = {
        formConfig: claimConfigRest,
        data: { id: claimTypeId, isAWFAttached: claimTypeData.isAWFAttached },
        currentUserRoleId,
        allowEdit,
        resubmittedClaim,
      };

      const res = await new GeoTagging().postLeave(payloadData);
      setLoading(LoadingStatus.Success);
      showToast(
        ToastMessages.success.replace(stringSubstitute, toastMessage.success),
        ToastThemes.success
      );
      return res;
    } catch (err) {
      setLoading(LoadingStatus.Failure);
      showToast(err?.response?.data?.message, ToastThemes.error);
      throw err?.response?.data?.message;
    }
  };

  const handleDiscard = () => {
    if (isNaN(claimId)) {
      navigate(-1);
    } else {
      setViewMode(true);
    }
  };

  const handleSaveAndAddNewClick = async () => {
    // const { entitleMentsErrors, checkFlag, dateError } =
    //   await checkForEntitlements(entitlement, formState);

    // if (entitleMentsErrors) {
    //   showToast(entitleMentsErrors, ToastThemes.error);
    //   return;
    // }
    await postClaim();
    setFormState({});
    // setBenefits(benefitId, claimId);
  };

  const handleSaveAndAddCopyClick = async () => {
    // const { entitleMentsErrors, checkFlag, dateError } =
    //   await checkForEntitlements(entitlement, formState);

    // if (entitleMentsErrors) {
    //   showToast(entitleMentsErrors, ToastThemes.error);
    //   return;}

    await postClaim();
    // setBenefits(benefitId, claimId);
    const { id, referenceNumber, status, createdUtc, fkCreatedBy, ...rest } =
      formState;
    setFormState(rest);
  };

  return (
    <Box>
      <CircularLoader show={LoadingStatus.Loading === loading} />
      <FormEdit
        list={formConfig}
        formInfo={formState}
        setFormData={setFormState}
        handleDropdownClick={handleDropdownClick}
        getDropdownOptions={getDropdownOptions}
        errors={validationErrors}
        setErrors={setValidationErrors}
        isActionHeader={true}
        publicHoliday={publicHoliday}
        actionHeader={{
          labelText: claimTypeData?.name || 'New Form',
          showDiscard: true,
          showPublish: true,
          showPublishModal: false,
          publishButtonText: 'Submit',
          onPublishClick: handleSaveClick,
          showSave: false,
          onClickDiscard: handleDiscard,
          labelCode: referenceNumber || '',
          handleSaveAndAddNewClick: handleSaveAndAddNewClick,
          handleSaveAndAddCopyClick: handleSaveAndAddCopyClick,
          firstButton: (onClick) =>
            !isMobile &&
            isNaN(claimId) && (
              <StyledButton onClick={onClick}>
                <ContentCopyOutlined
                  sx={{ width: '24px', height: '24px', mr: '5px' }}
                />
                Submit & Copy
              </StyledButton>
            ),
          secondButton: (onClick) =>
            !isMobile &&
            isNaN(claimId) &&
            claimTypeData?.claimTypeCode !== 'SC' && (
              <StyledButton onClick={onClick}>
                <AddOutlined
                  sx={{ width: '24px', height: '24px', mr: '5px' }}
                />
                Submit & New
              </StyledButton>
            ),
        }}
      />
    </Box>
  );
};

export default LeaveEditForm;
