import API from './api';

export class GeoTagging extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL || '';
  constructor() {
    super(GeoTagging.baseURL);
  }

  postAttendance = async (body) =>
    this.responsify(await this.instance.post('geo-tagging/capture', body));

  listAll = async (roleId, searchParam, startDate, endDate) =>
    this.responsify(
      await this.instance.get('geo-tagging/list-attendence', {
        params: { roleId, searchParam, startDate, endDate },
      })
    );

  details = async (roleId, id) =>
    this.responsify(
      await this.instance.get('geo-tagging/details', {
        params: { roleId, id },
      })
    );
  
  geoFencingDetails = async () =>
    this.responsify(await this.instance.get('geo-tagging/geo-fence-details'));  

  getWorkPlaceLeaveDetails = async (moduleId, id, roleId) =>
    this.responsify(
      await this.instance.get('work-place/get-details', {
        params: { moduleId, id, roleId },
      })
    );

  postLeave = async (body) =>
    this.responsify(
      await this.instance.post('work-place/create-update-time', body)
    );

  statusUpdate = async (body) =>
    this.responsify(await this.instance.post('work-place/status-update', body));

  leavelistAll = async (moduleId, roleId, searchParam, startDate, endDate) =>
    this.responsify(
      await this.instance.get('work-place/list', {
        params: { roleId, searchParam, startDate, endDate, moduleId },
      })
    );


    leaveStatusOnApprove = async (body) =>
      this.responsify(await this.instance.post('geo-tagging/update-per-day-leave-status', body));
}
