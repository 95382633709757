import { FormBuilderService } from '../../services/formBuilder';
import {
  WORK_ADD_CONFIG,
  WORK_DELETE_MODAL,
  WORK_EDIT_MODAL_DATA,
  GET_ALL_WORK_FORMS_LIST_FAILURE,
  GET_ALL_WORK_FORMS_LIST_REQUEST,
  GET_ALL_WORK_FORMS_LIST_SUCCESS,
  GET_CLAIM_WORK_FORM_CONFIG_FAILURE,
  GET_CLAIM_WORK_FORM_CONFIG_REQUEST,
  GET_CLAIM_WORK_FORM_CONFIG_SUCCESS,
  WORK_RESET_CONFIG,
} from './actionTypes';

export const addConfigWork = (payload) => ({
  type: WORK_ADD_CONFIG,
  payload,
});

export const editModalDataWork = (payload) => ({
  type: WORK_EDIT_MODAL_DATA,
  payload,
});

export const deleteModalWork = (payload) => ({
  type: WORK_DELETE_MODAL,
  payload,
});

const getConfigInfoRequest = () => ({
  type: GET_CLAIM_WORK_FORM_CONFIG_REQUEST,
});

const getConfigInfoSuccess = (payload) => ({
  type: GET_CLAIM_WORK_FORM_CONFIG_SUCCESS,
  payload,
});

const getConfigInfoFailure = () => ({
  type: GET_CLAIM_WORK_FORM_CONFIG_FAILURE,
});

const getAllFormsListRequest = () => ({
  type: GET_ALL_WORK_FORMS_LIST_REQUEST,
});

const getAllFormsListSuccess = (payload) => ({
  type: GET_ALL_WORK_FORMS_LIST_SUCCESS,
  payload,
});

const getAllFormsListFailure = () => ({
  type: GET_ALL_WORK_FORMS_LIST_FAILURE,
});

export const resetConfig = () => ({
  type: WORK_RESET_CONFIG,
});

export const getWorkFormConfig = (moduleId) => async (dispatch) => {
  dispatch(getConfigInfoRequest());
  return new FormBuilderService().getWorkFormConfig(moduleId)
    .then((res) => {
      dispatch(getConfigInfoSuccess(res));
      return res;
    })
    .catch(() => {
      dispatch(getConfigInfoFailure());
      return false;
    });
};

export const getAllFormsList = (parentName) => async (dispatch) => {
  dispatch(getAllFormsListRequest());
  new FormBuilderService().getChildForm(parentName)
    .then((res) => {
      dispatch(getAllFormsListSuccess(res));
    })
    .catch(() => {
      dispatch(getAllFormsListFailure());
    });
};
