import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import {
  GET_ALL_CLAIM_LIST_FAILURE,
  GET_ALL_CLAIM_LIST_REQUEST,
  GET_ALL_CLAIM_LIST_SUCCESS,
  GET_ALL_CLAIM_TAB_FAILURE,
  GET_ALL_CLAIM_TAB_REQUEST,
  GET_ALL_CLAIM_TAB_SUCCESS,
  RESET_CLAIMS,
  RESET_UNERGIZER_ERRORS,
  SET_UNERGIZER_ERROR,
} from './actionTypes';

const initialState = {
  requestProcessing: {
    [APIRequestStatus.GET_CLAIMS_TAB]: LoadingStatus.Loading,
    [APIRequestStatus.GET_CLAIMS_LIST]: LoadingStatus.Loading,
  },
  claimsTab: [],
  claimsList: [],
  approvalRemarksPermissions:{},
  unergizerErrors : []
};

export const newClaimsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CLAIM_TAB_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIMS_TAB]: LoadingStatus.Loading,
        },
      };
    }

    case GET_ALL_CLAIM_TAB_SUCCESS: {
      const res = action.payload;
      return {
        ...state,
        claimsTab: res,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIMS_TAB]: LoadingStatus.Success,
        },
      };
    }

    case GET_ALL_CLAIM_TAB_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIMS_TAB]: LoadingStatus.Failure,
        },
      };
    }

    case GET_ALL_CLAIM_LIST_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIMS_TAB]: LoadingStatus.Loading,
        },
      };
    }

    case GET_ALL_CLAIM_LIST_SUCCESS: {
      const {claimList,approvalRemarksPermission} = action.payload;
      return {
        ...state,
        claimsList: claimList,
        approvalRemarksPermissions:approvalRemarksPermission,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIMS_TAB]: LoadingStatus.Success,
        },
      };
    }

    case GET_ALL_CLAIM_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIMS_TAB]: LoadingStatus.Failure,
        },
      };
    }

    case SET_UNERGIZER_ERROR: {
      const res = action.payload;
      return {
        ...state,
        unergizerErrors: res,
      };
    }

    case RESET_UNERGIZER_ERRORS: {
      return {
        ...state,
        unergizerErrors: [],
      };
    }

    case RESET_CLAIMS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
