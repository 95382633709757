import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import FieldPicker from './field-picker.component';
import Calculator from '../../../../../../atoms/Calculator/calculator.component';
import FunctionPicker from './function-picker.component';

const PerformCalculations = (props) => {
  const {
    handleFieldClick,
    handleCalculatorClick,
    handleBackClick,
    expressionVal,
    handleFunctionClick,
    list
  } = props;
  const [selectedPanel, setSelectedPanel] = useState('Operator');
  const handlePanelChange = (panel) => {
    setSelectedPanel(panel);
  };
  return (
    <Box
      border="1px solid #A7ECE2"
      display="grid"
      gridTemplateColumns="280px 350px 280px"
      gridTemplateRows="40px 260px"
      width="fit-content"
      height="fit-content"
    >
      <Box>
        <Typography p={1}>Fields</Typography>
        <FieldPicker handleClick={handleFieldClick} 
        list={list}
        />
      </Box>
      <Box>
        <Typography
          p={1}
          borderLeft="1px solid #eeeeee"
          borderBottom="1px solid #eeeeee"
        >
          Formula
        </Typography>
        <Box p={1}>{expressionVal}</Box>
      </Box>
      <Box>
        <Box
          borderBottom="1px solid #eeeeee"
          display="grid"
          gridTemplateColumns="140px 140px"
          width="fit-content"
          height="fit-content"
        >
          <Typography
            p={1}
            borderLeft="1px solid #eeeeee"
            onClick={() => handlePanelChange('Operator')}
            style={{
              cursor: 'pointer',
              fontWeight: selectedPanel === 'Operator' ? 'bold' : 'normal',
            }}
          >
            Operator
          </Typography>
          <Typography
            p={1}
            borderLeft="1px solid #eeeeee"
            onClick={() => handlePanelChange('Function')}
            style={{
              cursor: 'pointer',
              fontWeight: selectedPanel === 'Function' ? 'bold' : 'normal',
            }}
          >
            Function
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderLeft="1px solid #eeeeee"
        >
          {selectedPanel === 'Operator' ? (
            <Calculator
              handleDigitClick={handleCalculatorClick}
              handleBackClick={handleBackClick}
            />
          ) : (
            <FunctionPicker handleClick={handleFunctionClick} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PerformCalculations;
