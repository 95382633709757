import { Box, Container, Grid, Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import TabBar from '../../../../atoms/Tabs/TabBar';
import AddLeaveForm from './add-leave-form/add-leave-form';

const ManageLeaveFormTab = (props) => {
  const [viewMode, setViewMode] = useState(false);
  const paths = location.pathname.split('/');
  const idForDetails = Number(paths[paths.length - 1]);
  const [data, setData] = useState();
  const moduleId = Number(paths[paths.length - 2]);
  const { isMobile } = useSelector((state) => state.main);

  //   const setBenefits = (benefitId, claimId) => {
  //     if (!isNaN(claimId)) {
  //       new BuilderServices()
  //         .getEntitlements(benefitId, claimTypeId, claimId)
  //         .then((res) => {
  //           if (res) {
  //             setEnitilements(res);
  //           }
  //         });
  //     } else {
  //       new BuilderServices()
  //         .getEntitlements(benefitId, claimTypeId)
  //         .then((res) => {
  //           if (res) {
  //             setEnitilements(res);
  //           }
  //         });
  //     }
  //   };

  const claimsTab = [
    {
      name: 'leave',
      label: data?.name || 'New Form',
      title: data?.name || 'New Form',
      hide: false,
      panel: (
        <AddLeaveForm
          viewMode={viewMode}
          setViewMode={setViewMode}
          setData={setData}
        />
      ),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container>
        <Grid container>
          {/* {viewMode ? (
            <ClaimedEmployeeDetails employee={claimHeaderData} />
          ) : (
            <ClaimedEmployeeDetails
              employee={!isEmpty(claimHeaderData) ? claimHeaderData : ''}
            />
          )} */}

          {/* {!claimData?.isMultiDepartment &&
            !claimData?.isUnergizer &&
            claimData?.isBenefitAttached &&
            claimData?.fkBenefitId &&
            !viewMode && (
              <EntitlementsView
                entitlements={enitilements}
                BenefitsAttached={claimData?.fkBenefitId}
              />
            )} */}
          <Box sx={{ flexGrow: 1, width: '100%' }}>
            <TabBar
              tabPanels={claimsTab}
              searchTabs = {!isMobile}
              disableCreate
              isScroll={false}
            />
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default ManageLeaveFormTab;
