import { Box, Grid } from '@mui/material';
import React from 'react';
import FieldView from '../../../molecules/FieldView/FieldView';
import { getDateWithTimeZone } from '../../../../utils/formatter';
import ActionHeader from '../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import { useNavigate } from 'react-router-dom';

const SelfieFormView = (props) => {
  const { data, setViewMode } = props;
  const {
    pkId,
    fkUserId,
    locationLat,
    locationLong,
    geoLocation,
    attendanceDatetimeUtc,
    attendanceInputCode,
    attendanceInOutDirection,
    attendanceDatetimeTimezone,
    attendanceDatetimeTzOffset,
    createdUtc,
    userImageUrl,
    schedule,
  } = data;

  const { id, displayName, workSchedule } = fkUserId || {};
  const navigate = useNavigate();

  const timeZone = attendanceDatetimeTzOffset?.replace('UTC', '').trim();
  const handleDiscard = () => {
    navigate(-1);
  };
  
  return (
    <>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={displayName || 'New User'}
          showSave={attendanceInputCode  === 'M'}
          showBackButton
          onBackClick={handleDiscard}
          editButtonText="Edit"
          onEditClick={() => setViewMode(false)}
        />
      </Box>
      <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
        <Grid item md={6} xs={12}>
          <FieldView labelText="Employee Name" labelValue={displayName} />
        </Grid>
        <Grid item md={6} xs={12}>
          <FieldView
            labelText="Work Shift"
            labelValue={schedule ? `${schedule.name} (${schedule.code})` : ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FieldView
            labelText="Punch Type"
            labelValue={attendanceInOutDirection === 'I' ? 'In' : 'Out'}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FieldView
            labelText="Attendance Date/Time"
            labelValue={
              attendanceDatetimeUtc
                ? getDateWithTimeZone(
                    attendanceDatetimeUtc,
                    'DD/MMM/YYYY hh:mm a',
                    timeZone
                  )
                : ''
            }
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <FieldView
            labelText="User Captured Image"
            labelValue={
              <a
                href={userImageUrl}
                // download={`${crypto.randomUUID()}.png`}
                style={{ pointerEvents: 'auto' }}
                target="_blank" rel="noreferrer"
              >
                {userImageUrl ? <img src={userImageUrl} height={80} /> : ''}
              </a>
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FieldView labelText="Latitude" labelValue={locationLat} />
        </Grid>
        <Grid item md={6} xs={12}>
          <FieldView labelText="Longitude" labelValue={locationLong} />
        </Grid>
        {/* <Grid item md={6} xs={12} >
        <FieldView labelText="Geo Location" labelValue={geoLocation} />
      </Grid> */}
      </Grid>
    </>
  );
};

export default SelfieFormView;
