import {
  InfoOutlined,
  SettingsBackupRestoreOutlined,
} from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ListView from '../../../atoms/DataList/ListView';
import { useDebounce } from 'usehooks-ts';
import {
  DebounceTimer,
  LoadingStatus,
  ToastThemes,
} from '../../../constants/common';
import {
  firstDateOfCurrentMonth,
  getFormattedValues,
  lastDateOfCurrentMonth,
} from '../../../utils/formatter';
import TryAgain from '../../molecules/ErrorState/error.component';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import { DateRangePro } from '../../molecules/DateRange/DateRangePro';
import { permissionsObj } from '../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { GeoTagging } from '../../../services/geoTagging';
import { showToast } from '../../../utils/common.util';

const AllLeaveDashboard = () => {
  const { currentUserRoleId, isMobile } = useSelector((state) => state.main);
  const [checkedItems, setCheckedItems] = useState([]);
  const [list, setList] = useState([]);
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const defaultMonths = logoImage?.defaultMonths;
  const defaultTimezone = logoImage?.defaultTimezone;
  const [loading, setLoading] = useState(-1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const paths = location.pathname.split('/');
  const moduleId = Number(paths[paths.length - 1]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState([
    firstDateOfCurrentMonth,
    lastDateOfCurrentMonth,
  ]);
  const searchQuery = searchParams?.get('q');
  const debouncedVal = useDebounce(searchQuery, DebounceTimer);

  const { timeManagementPermission } = useSelector(
    (state) => state.modulePermissions
  );
  const { allDashboardList } =
    useSelector((state) => state.workSpaceBuilder) || {};

  const module = allDashboardList.find(
    (cell) => cell.id === Number(moduleId)
  )?.moduleName;

  useEffect(async () => {
    if (currentUserRoleId) {
      setLoading(LoadingStatus.Loading);
      await new GeoTagging()
        .leavelistAll(
          moduleId,
          currentUserRoleId,
          searchQuery,
          getFormattedValues('YYYY-MM-DD', selectedDate[0]),
          getFormattedValues('YYYY-MM-DD', selectedDate[1])
        )
        .then((res) => {
          setLoading(LoadingStatus.Success);
          setList(res.list);
        })
        .catch((err) => {
          setLoading(LoadingStatus.Failure);
          showToast(err?.response?.data?.message, ToastThemes.error);
          throw err?.response?.data?.message;
        });
    }
  }, [currentUserRoleId, moduleId, debouncedVal, selectedDate]);

  useEffect(() => {
    setCheckedItems([]);
  }, [list]);

  const handleAddClick = () => {
    navigate('create');
  };

  const rowData = list?.map((items) => {
    const {
      id,
      referenceNumber,
      status,
      leaveDaysCount,
      leaveEndDate,
      leaveStartDate,
    } = items;
    return {
      referenceNumber,
      leaveDaysCount: isMobile ? {hide:true} : leaveDaysCount || 0.0,
      leaveStartDate: isMobile ? {hide:true}: leaveStartDate,
      leaveEndDate: isMobile ? {hide:true}: leaveEndDate,
      status,
      action: (
        <IconButton onClick={() => navigate({ pathname: `${id}` })}>
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        value: {
          id,
          referenceNumber,
          status,
        },
        hide: true,
      },
    };
  });

  const isDisabledReset = () => {
    return (
      selectedDate[0].isSame(firstDateOfCurrentMonth) &&
      selectedDate[1].isSame(lastDateOfCurrentMonth)
    );
  };

  const resetFilters = () => {
    setSelectedDate([firstDateOfCurrentMonth, lastDateOfCurrentMonth]);
  };

  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      <Box sx={{ backgroundColor: 'white' }}>
        <Box display="flex" justifyContent="flex-end" gap={2} mb={1} p={2}>
          <DateRangePro
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <Button
            startIcon={<SettingsBackupRestoreOutlined />}
            sx={{ textTransform: 'capitalize', mt: -1.5 }}
            disabled={isDisabledReset()}
            onClick={resetFilters}
          >
            Clear
          </Button>
          {/* {(fkModuleId?.permissions[0]?.viewAccess ===
            ViewAccess.APPROVER_APPROVE_ACCESS ||
            fkModuleId?.permissions[0]?.viewAccess ===
              ViewAccess.ALL_APPROVED_ACCESS) && (
            <Box pr={1}>
              <ApproveRejectModal
                checkedItems={checkedItems}
                currentUserRoleId={currentUserRoleId}
                moduleId={fkModuleId?.id}
                viewAccess={fkModuleId?.permissions[0]?.viewAccess}
                claimTypeId={ctId}
                searchQuery={searchQuery}
                selectedDate={selectedDate}
                approvalRemarksPermissions={approvalRemarksPermissions}
              />
            </Box>
          )} */}
        </Box>
        <ListView
          headCells={[
            //     {
            //     id: 'empName',
            //     label: 'Employee Name',
            //   },
            {
              id: 'referenceNumber',
              label: 'Reference Number',
              disablePadding : isMobile
            },
            {
              id: 'leaveDaysCount',
              label: 'Leave Count',
              hide: isMobile
            },
            {
              id: 'leaveStartDate',
              label: 'Leave Start Date',
              dataType: 'date',
              dateFormat: 'YYYY-MM-DD',
              hide: isMobile
            },
            {
              id: 'leaveEndDate',
              label: 'Leave End Date',
              dataType: 'date',
              dateFormat: 'YYYY-MM-DD',
              hide: isMobile
            },
            {
              id: 'status',
              label: 'Status',
            },
          ]}
          rowsData={rowData || []}
          showSerialNo={!isMobile}
          showCheckbox={!isMobile}
          setCheckedItems={setCheckedItems}
          checkedItems={checkedItems}
          allowSelectAll={true}
          onAddClick={handleAddClick}
          showAddIcon={
            timeManagementPermission?.[module]?.[permissionsObj.allowAdd] ||
            false
          }
        />
      </Box>
    </>
  );
};
export default AllLeaveDashboard;
