import {
  createStore, combineReducers, applyMiddleware, compose
} from 'redux';
import rootReducer from './reducers/employee-portal/employee-portal.reducers';
import thunk from 'redux-thunk';
import onBoardingReducer from './onboarding/reducer';
import mainReducer from './main/reducer';
import userOnboardingReducer from './userOnboarding/reducer';
import rolesAndPermissionsReducer from './rolesAndPermissions/reducer';
import countriesAndCitiesReducer from './countriesAndCities/reducer';
import modulePermissions from './modulePermissions/reducer';
import formConfigReducer from './formConfig/reducer';
import { goalsReducer } from './goals/reducer';
import { jobReducer } from './jobRecruiting/reducer';
import { candidateReducer } from './candidateDetails/reducer';
import { interviewReducer } from './interviewSchedule/reducer';
import { timeManagementReducer } from './timeManagement/reducer';
import { salaryStructureReducer } from './salaryStructure/reducer';
import { approvalWorkflowReducer } from './approvalFlow/reducer';
import { payrollAssignmentReducer } from './payrollAssignment/reducer';
import { payrollReducer } from './payroll/reducer';
import { formBuilderReducer } from './form-builder/reducer';
import { newClaimsReducer } from './newClaims/reducer';
import { benefitsReducer } from './benefits/reducer';
import { currencyCoversionReducer } from './currency-conversion/reducer';
import { userOnboardingBuilderReducer } from './user-onboarding-builder/reducer';
import { workSpaceBuilderReducer } from './work-space-builder/reducer';
  
const middlewares = [thunk];
  
const middleware = (applyMiddleware(...middlewares));
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  combineReducers({
    claims: rootReducer,
    onBoarding: onBoardingReducer,
    main: mainReducer,
    userOnboarding: userOnboardingReducer,
    rolesAndPermissions: rolesAndPermissionsReducer,
    countriesAndCities: countriesAndCitiesReducer,
    modulePermissions: modulePermissions,
    formConfig: formConfigReducer,
    goals: goalsReducer,
    job: jobReducer,
    candidate: candidateReducer,
    interview: interviewReducer,
    timeManagement: timeManagementReducer,
    salaryStructure: salaryStructureReducer,
    approvalWorkflow:approvalWorkflowReducer,
    payrollAssignment: payrollAssignmentReducer,
    payroll: payrollReducer,
    formBuilder: formBuilderReducer,
    newClaims: newClaimsReducer,
    benefits: benefitsReducer,
    currencyConversion :currencyCoversionReducer,
    userOnboardingBuilder: userOnboardingBuilderReducer,
    workSpaceBuilder: workSpaceBuilderReducer
  }),
  composeEnhancers(middleware)
);
