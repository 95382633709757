import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ComponentFormView from './ComponentFormView';
import { LightTooltip } from '../../../../../atoms/Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';

const ComponentFormConfig = (props) => {
  const {
    formInfo,
    setFormInfo,
    index,
    payrollEditDisabled,
    readOnly,
    errors,
    setPayrollError,
    setIsPayrollChange,
  } = props;
  const [componentformInfo, setComponentFormInfo] = useState(formInfo);

  useEffect(() => {
    setFormInfo((prev) => {
      const newData = [...prev];
      newData[index] = { ...componentformInfo };
      return newData;
    });
  }, [componentformInfo]);

  const handleInputChange = (event) => {
    const key = event.target.name;
    const val = event.target.value === '' ? '' : Number(event.target.value);
    if (val === '' || (val >= 0 && !isNaN(val))) {
      setComponentFormInfo((prev) => ({
        ...prev,
        id: key,
        value: val === '' ? val : Math.max(val, 0),
      }));
    }
    setPayrollError((prevState) => ({
      ...prevState,
      additionErrors: prevState.additionErrors?.map((errorObj) => {
        if (errorObj.id === key) {
          return { ...errorObj, error: '' };
        }
        return errorObj;
      }),
      deductionErrors: prevState.deductionErrors?.map((errorObj) => {
        if (errorObj.id === key) {
          return { ...errorObj, error: '' };
        }
        return errorObj;
      }),
    }));
    setIsPayrollChange(true);
  };

  const preventNegativeKeyPress = (event) => {
    if (event.key === '-' || event.key === 'e' || event.key === '+') {
      event.preventDefault();
    }
  };
  return (
    <>
      {readOnly ? (
        <ComponentFormView formInfo={formInfo} />
      ) : (
        <Box marginTop={3} display="flex" justifyContent="space-between">
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {componentformInfo?.name}
            {componentformInfo?.cpfWageClassificationCode && (
              <LightTooltip
                title={`${componentformInfo?.cpfWageClassificationCode}`}
                placement="top"
                arrow
              >
                <Box color="primary.main" sx={{ display: 'inline-block' }}>
                  <InfoOutlined
                    sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                  />
                </Box>
              </LightTooltip>
            )}
          </Typography>
          <Box maxWidth={100}>
            <TextField
              onKeyDown={preventNegativeKeyPress}
              placeholder="type here"
              size="small"
              fullWidth={true}
              onChange={handleInputChange}
              name={formInfo?.id}
              type="number"
              value={
                componentformInfo?.value === ''
                  ? ''
                  : Math.max(componentformInfo?.value || 0, 0)
              }
              disabled={payrollEditDisabled}
              required={true}
              error={errors?.id === componentformInfo?.id ? errors?.error : ''}
              helperText={
                errors?.id === componentformInfo?.id ? errors?.error : ''
              }
              inputProps={{ min: '0' }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ComponentFormConfig;
