import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import './Card.css';

const CardUI = (props) => {
  return (
    <React.Fragment>
      <Card
        sx={{ minWidth: 275 }}
        style={{
          padding: props.padding ? props.padding : '10px 10px 15px 10px',
          boxShadow: props.boxShadow ? props.boxShadow : '0 0 16px #ebebeb',
          borderRadius: props.borderRadius ? props.borderRadius : '10px',
          borderBottomStyle: props.borderBottomStyle
            ? props.borderBottomStyle
            : '',
          borderBottomColor: props.borderBottomColor
            ? props.borderBottomColor
            : '',
          background: props.background,
          margin: props.margin ? props.margin : '',
          width: props.width ? props.width : '',
          height: props.height ? props.height : '',
          position: props.gradient && 'relative',
          border: props.gradient && 'none',
          backdropFilter: props.gradient && 'blur(10px)', // Adds a subtle blur effect
        }}
        onClick={props.onClick}
        className={props.className}
      >
        {/* Gradient Border using pseudo-elements */}
        {props.gradient && (
          <div
            style={{
              content: '""',
              position: 'absolute',
              inset: 0,
              padding: '4px',
              borderRadius: '10px',
              background:
                props.borderGradient ||
                'linear-gradient(45deg, #9b59b6, #3498db, #2ecc71)', // Purple-Blue-Green Gradient
              WebkitMask:
                'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              WebkitMaskComposite: 'xor',
              maskComposite: 'exclude',
              pointerEvents: 'auto',
            }}
          ></div>
        )}
        <CardContent>{props.children}</CardContent>
      </Card>
    </React.Fragment>
  );
};
export default CardUI;
