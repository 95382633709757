import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FormView from '../../../OnBoarding/common/Form/FormView';
import KRAFormView from '../../Goals/KRA/KRATab/KRAForm/KRAFormView';
import AccordionTabs from '../../../../atoms/Accordian/Accordian';
import {
  GoalFormHeaders,
  PerformanceChild,
  stringSubstitute,
} from '../../Goals/common/constants';
import CompetencyView from '../../Goals/Competency/competencyView';
import { useDispatch, useSelector } from 'react-redux';
import ActionHeader from '../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import TotalScore from '../../Goals/TotalScore/TotalScore';
import {
  AppraisalStatus,
  CompetencyFormFieldNames,
  TotalScoreFormFieldNames,
} from '../../Utlis/goals.util';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  calculateTotalScore,
  postAppraisalDetails,
  getAppraisalDetails,
} from '../../../../redux/goals/action';
import { showToast } from '../../../../utils/common.util';
import {
  ToastMessages,
  ToastValidationMeassge,
} from '../../../OnBoarding/Organization/constants/onboarding.constants';
import {
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../constants/common';
import { useNavigate } from 'react-router-dom';
import { GoalsServices } from '../../../../services/goals';
import Loading from '../../../../atoms/Loading/loading';
import {
  getMandatoryFields,
  getValidationErrors,
} from '../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import StyledButton from '../../../OnBoarding/Organization/ActionHeader/ActionHeader.styled';
import KraAndCompentencyTable from './MangerRemarks/KraTable';
import ApprovalWorkflowFooterCommon from '../../../OnBoarding/Organization/ApprovalFlow/Footer/ApprovalWorkflowFooterCommon';
import { AppraisalReasonList } from '../../../../constants/Reasons';
import { PayrollStatus } from '../../../Payroll/Constant/Constant';
import ApprovalWorkflowAuditLog from '../../../OnBoarding/Organization/ApprovalFlow/ApprovalWorkflowUtilsCommon/ApprovalWorkflowAuditTrail';

const AppraisalForm = (props) => {
  const { appraisalId, res, currentUserRoleId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    goalsFormData,
    kraFormData,
    competencyData,
    goalsConfig,
    kraConfig,
    totalScoreData,
    kraFormDataLocal,
    goalInfo,
    competencyRatingScale,
    totalScoreConfig,
    auditTrail,
  } = useSelector((state) => state.goals);
  const { overallRemarks: remarks, ...rest } = goalInfo;
  const currentUser = useSelector((state) => state.main.userInfo.id);
  const { performancePermission } = useSelector((state) => state.modulePermissions);
  const moduleId = performancePermission?.[PerformanceChild]?.id;
  const [isEditable,setIsEditable]= useState(false);
  const [competencyFormData, setCompetencyFormData] = useState(competencyData);
  const [viewMode, setViewMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [overallRemarks, setOverallRemarks] = useState({
    [TotalScoreFormFieldNames.OverallRemarks]: remarks,
  });
  const [overallScoreError, setOverallScoreError] = useState();
  const [competencyValidationError, setCompetencyValidationError] = useState(
    Array(competencyData.length).fill('')
  );
  const [postLoading, setPostLoading] = useState(-1);
  const [appraiserRemarksCompetency, setAppraiserRemarksCompetency] = useState(
    Array(competencyData.length).fill({})
  );
  const [managerAcess, setManagerAcess] = useState(false);
  const [tableData, setTableData] = useState({});
  const [isAuthorized, setisAuthorized] = useState(false);
  
  useEffect(() => {
    dispatch(calculateTotalScore(competencyFormData));
  }, [competencyFormData]);

  const handleEdit = () => {
    // setViewMode(false);
  };
  const overallRequiredFields = useMemo(() => {
    return getMandatoryFields(totalScoreConfig);
  }, [totalScoreConfig]);

  const saveDetails = useCallback(async (action) => {
    switch (action) {
      case PayrollStatus.Approved: {  
        new GoalsServices().statusUpdateAppraisal({
          action: action === PayrollStatus.Approved ? 'APPROVED' : 'REJECTED',
          appraisalId:Number(appraisalId),
        });
        break;
      }
      case PayrollStatus.Rejected: {
        new GoalsServices().statusUpdateAppraisal({
          action: action === PayrollStatus.Approved ? 'APPROVED' : 'REJECTED',
          appraisalId:Number(appraisalId),
        });
        break;
      }
      case PayrollStatus.SendBack: {
        new GoalsServices().statusUpdateAppraisal({
          action: 'SENT BACK',
          appraisalId:Number(appraisalId),
        });
        break;
      }
      case PayrollStatus.Pending: {
        new GoalsServices().statusUpdateAppraisal({
          action: 'PENDING',
          appraisalId:Number(appraisalId),
        });
        break;
      }
    }
  }, []);

  const getCompetancyErrors = () => {
    const competencyErrors = [];
    competencyFormData.forEach((formData) => {
      const errors = isEmpty(
        formData[CompetencyFormFieldNames.Submission][
          CompetencyFormFieldNames.Remarks
        ]
      )
        ? 'Remarks Can\'t be empty'
        : '';
      competencyErrors.push(errors);
    });
    return competencyErrors;
  };
  const handleSave = () => {
    const overallFormErrors = getValidationErrors(
      overallRemarks,
      overallRequiredFields
    );
    const competencyErrors = getCompetancyErrors();
    const hasCompetencyErrors =
      competencyErrors.findIndex((data) => !isEmpty(data)) !== -1;
    setPostLoading(LoadingStatus.Loading);

    if (isEmpty(overallFormErrors) && !hasCompetencyErrors) {
      dispatch(
        postAppraisalDetails(
          kraFormDataLocal,
          competencyFormData,
          { ...totalScoreData, ...overallRemarks },
          parseInt(appraisalId),
          currentUserRoleId,
          moduleId
        )
      )
        .then(() => {
          setViewMode(true);
          showToast(
            ToastMessages.success.replace(
              stringSubstitute,
              toastMessage.success
            ),
            ToastThemes.success
          );
          setPostLoading(LoadingStatus.Success);
          dispatch(getAppraisalDetails(currentUserRoleId, res, appraisalId,moduleId));
        })
        .catch((err) => {
          showToast(err, ToastThemes.error);
          setPostLoading(LoadingStatus.Failure);
        });
    } else {
      setPostLoading(LoadingStatus.Failure);
      setOverallScoreError(overallFormErrors);
      setCompetencyValidationError(competencyErrors);
      showToast(ToastValidationMeassge, ToastThemes.error);
    }
  };

  const handleDiscard = () => {
    // if (appraisalSubmitted) {
    //   setViewMode(true);
    // } else {
      navigate('../');
    // }
  };

  const handleAddAppraiserRemarks = () => {
    setLoading(true);
    const kraPerformanceAppraiserRemarks = kraFormDataLocal
      .map((item) => {
        if (
          item?.kraPerformance &&
          item?.kraPerformance.fkKra &&
          item?.kraPerformance?.mgrRemarks
        ) {
          const { fkKra, mgrRemarks } = item.kraPerformance;
          return { fkKra, mgrRemarks };
        }
      })
      .filter((item) => item !== undefined);
    const competencyAppraiserRemarks = appraiserRemarksCompetency.filter(
      (item) => item?.mgrRemarks && item?.competencyId
    );
    new GoalsServices()
      .postAppraiserRemarks(
        competencyAppraiserRemarks,
        kraPerformanceAppraiserRemarks,
        Number(appraisalId)
      )
      .then(() => {
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.success),
          ToastThemes.success
        );
        dispatch(getAppraisalDetails(currentUserRoleId, res, appraisalId,moduleId));
      })
      .catch((err) => {
        showToast(err, ToastThemes.error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    new GoalsServices().getAppraiserRemarks(appraisalId).then((res) => {
      setTableData(res);
    });
  }, [appraisalId]);

  useEffect(()=>{
    setManagerAcess(isAuthorized);
  },[isAuthorized]);

  return (
    <Loading loading={loading}>
      <CircularLoader show={postLoading === LoadingStatus.Loading} />
      <Box>
        <Box>
          <Box mb={3} mx={-3} mt={-3}>
            <ActionHeader
              labelText="Self Appraisal"
              editButtonText={viewMode ? 'Edit' : 'Save'}
              onEditClick={viewMode ? handleEdit : handleSave}
              handleFirstButtonClick={handleAddAppraiserRemarks}
              labelCode={goalInfo?.user.displayName || ''}
              firstButton={
                managerAcess &&
                ((onClick) => (
                  <StyledButton onClick={onClick}>
                    <EditOutlinedIcon
                      sx={{ width: '24px', height: '24px', mr: '5px' }}
                    />
                    Save
                  </StyledButton>
                ))
              }
              showDiscard={!viewMode && !managerAcess}
              showSave={
                isEditable || (currentUser === goalInfo?.user.id && goalInfo?.fkGoalStatus.id === AppraisalStatus.Activated)
              }
              onClickDiscard={handleDiscard}
            />
          </Box>

          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item md={8}>
              <Box
                py={2}
                px={3}
                bgcolor={'neutral.light80'}
                borderRadius={'8px 8px 0 0'}
              >
                <Typography variant="body2Bold" color={'neutral.dark80'}>
                  Goal:
                </Typography>
              </Box>
              <Paper sx={{ p: 3 }}>
                <FormView list={goalsConfig} info={goalsFormData} />
                <Box mt={3}>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item md={12}>
                      <AccordionTabs
                        title="Self Appraisal Score"
                        tabDetails={
                          <TotalScore
                            overallRemarks={overallRemarks}
                            setOverallRemarks={setOverallRemarks}
                            error={overallScoreError}
                            setError={setOverallScoreError}
                            viewMode={viewMode}
                            managerAcess={managerAcess}
                          />
                        }
                      />
                      {kraConfig.map((kraFormConfig, index) => (
                        <AccordionTabs
                          key={index}
                          title={`${kraFormConfig?.[0]?.fieldLabel} ${index + 1}`}
                          tabDetails={
                            <>
                              <KRAFormView
                                formConfig={kraFormConfig}
                                formData={kraFormData[index]}
                                kraIndex={index}
                                isAppraisal
                                viewMode={viewMode}
                                managerAcess={managerAcess}
                              />
                              <KraAndCompentencyTable
                                data={
                                  tableData?.hrmUserMgrKraSubmission?.filter(
                                    (item) =>
                                      item?.fkKraId?.id ===
                                      kraFormData[index].id
                                  ) || []
                                }
                                header="Appraiser Remarks"
                              />
                            </>
                          }
                        />
                      ))}
                      <AccordionTabs
                        title="Competency"
                        tabDetails={
                          <>
                            <CompetencyView
                              data={competencyData}
                              maxScoreCompetency={competencyRatingScale}
                              setData={setCompetencyFormData}
                              isAppraisal
                              viewMode={viewMode}
                              competencyFormData={competencyFormData}
                              competencyError={competencyValidationError}
                              setCompetencyError={setCompetencyValidationError}
                              appraiserRemarksCompetency={
                                appraiserRemarksCompetency
                              }
                              setAppraiserRemarksCompetency={
                                setAppraiserRemarksCompetency
                              }
                              managerAcess={managerAcess}
                            />
                            <KraAndCompentencyTable
                              data={
                                tableData?.hrmUserMgrCompentencySubmission || []
                              }
                              header="Competency Appraiser Remarks"
                            />
                          </>
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4}>
              <Box
                ml={6}
                pl={6}
                sx={{ borderLeft: 'solid 1px', borderColor: 'neutral.dark40' }}
                mb={3}
              >
                <Typography variant="subtitle2" color={'neutral.dark80'} mb={3}>
                  Audit Trail
                </Typography>
                <ApprovalWorkflowAuditLog
                  auditTrail={auditTrail}
                  defaultTimezone={''}
                />
              </Box>
            </Grid>
          </Grid>
          <ApprovalWorkflowFooterCommon
            recordId={Number(appraisalId)}
            moduleId={moduleId}
            reasons={AppraisalReasonList}
            refId={''}
            setIsEditable={setIsEditable}
            saveDetails={saveDetails}
            handleApprove={() => navigate(-1)}
            claimRaisingFor={undefined}
            isAuthorized={isAuthorized}
            setisAuthorized={setisAuthorized}
          />
        </Box>
      </Box>
    </Loading>
  );
};

export default AppraisalForm;
