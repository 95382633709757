import React, { useEffect, useState } from 'react';
import { LoadingStatus } from '../../../../constants/common';
import TryAgain from '../../../molecules/ErrorState/error.component';
import WorkSpaceBuilder from './work-space-builder';

const AddWorkForm = (props) => {
  const {
    loading,
    list,
    claimData,
    idForFormDetails,
    formInfo,
    setFormInfo,
    handleFormSaveClick,
  } = props;

  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  useEffect(() => {
    setFormInfo(formInfo);
  }, [formInfo]);

  return (
    <>
      <WorkSpaceBuilder
        list={list || []}
        claimData={claimData || {}}
        idForFormDetails={idForFormDetails}
        formInfo={formInfo}
        setFormInfo={setFormInfo}
        handleFormSaveClick={handleFormSaveClick}
      />
    </>
  );
};

export default AddWorkForm;
