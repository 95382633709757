import {
  InfoOutlined,
  SettingsBackupRestoreOutlined,
} from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import ListView from '../../../atoms/DataList/ListView';
import {
  DebounceTimer,
  LoadingStatus,
  ToastThemes,
} from '../../../constants/common';
import { DateRangePro } from '../../molecules/DateRange/DateRangePro';
import {
  firstDateOfCurrentMonth,
  getDateWithTimeZone,
  getFormattedValues,
  getSGTTimeZone,
  lastDateOfCurrentMonth,
} from '../../../utils/formatter';
import { showToast } from '../../../utils/common.util';
import { GeoTagging } from '../../../services/geoTagging';
import { permissionsObj } from '../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';

const AttendanceFormDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [checkedItems, setCheckedItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState([
    firstDateOfCurrentMonth,
    lastDateOfCurrentMonth,
  ]);
  const [loading, setLoading] = useState(-1);
  const [data, setData] = useState([]);

  const { currentUserRoleId } = useSelector((state) => state.main);
  const { timeManagementPermission } = useSelector(
    (state) => state.modulePermissions
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const searchInput = searchParams.get('q');
  const debouncedVal = useDebounce(searchInput, DebounceTimer);

  useEffect(async () => {
    if (currentUserRoleId) {
      setLoading(LoadingStatus.Loading);
      await new GeoTagging()
        .listAll(
          currentUserRoleId,
          searchInput,
          getFormattedValues('YYYY-MM-DD', selectedDate[0]),
          getFormattedValues('YYYY-MM-DD', selectedDate[1])
        )
        .then((res) => {
          setLoading(LoadingStatus.Success);
          setData(res);
        })
        .catch((err) => {
          showToast(
            err?.response?.data?.message || 'Something Went Wrong! Try Again',
            ToastThemes.error
          );
          setLoading(LoadingStatus.Failure);
        });
    }
  }, [currentUserRoleId, debouncedVal, selectedDate]);

  // useEffect(() => {
  //   setCheckedItems([]);
  // }, [allDashboardList]);

  const rowData = data?.map((item) => {
    const {
      pkId,
      fkUserId,
      locationLat,
      geoLocation,
      locationLong,
      attendanceInOutDirection,
      attendanceDatetimeUtc,
      attendanceDatetimeTzOffset,
      attendanceInputCode,
      schedule,
      status,
    } = item;
    const timeZone = attendanceDatetimeTzOffset.replace('UTC', '').trim();
    return {
      Attat: attendanceDatetimeUtc
        ? getDateWithTimeZone(
            attendanceDatetimeUtc,
            'DD/MMM/YYYY hh:mm a',
            timeZone
          )
        : '',
      pt: attendanceInOutDirection === 'I' ? 'In' : 'Out',
      la: locationLat || '',
      lo: locationLong || '',
      empName: fkUserId.displayName || '',
      work: schedule ? `${schedule.name} (${schedule.code})` : '',
      source: attendanceInputCode,
      status: (
        <Typography color={status != 'COMPLETE' ? 'error' : 'inherit'}>
          {status}
        </Typography>
      ),
      action: (
        <IconButton onClick={() => navigate({ pathname: `${pkId}` })}>
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        hide: true,
        value: pkId,
      },
    };
  });

  const isDisabledReset = () => {
    return (
      selectedDate[0].isSame(firstDateOfCurrentMonth) &&
      selectedDate[1].isSame(lastDateOfCurrentMonth)
    );
  };

  const resetFilters = () => {
    setSelectedDate([firstDateOfCurrentMonth, lastDateOfCurrentMonth]);
  };

  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      <Box sx={{ backgroundColor: 'white' }}>
        <Box display="flex" justifyContent="flex-end" gap={2} mb={1} p={2}>
          <DateRangePro
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <Button
            startIcon={<SettingsBackupRestoreOutlined />}
            sx={{ textTransform: 'capitalize', mt: -1.5 }}
            disabled={isDisabledReset()}
            onClick={resetFilters}
          >
            Clear
          </Button>
        </Box>
        <ListView
          headCells={[
            {
              id: 'Attat',
              label: 'Attendance At',
              dataType: 'date',
              dateFormat: 'DD/MMM/YYYY hh:mm a',
            },
            {
              id: 'pt',
              label: 'Punch Type',
            },
            {
              id: 'la',
              label: 'Latitude',
            },
            {
              id: 'lo',
              label: 'Longitude',
            },
            {
              id: 'empName',
              label: 'Employee Name',
            },
            {
              id: 'work',
              label: 'Work Shift',
            },
            {
              id: 'source',
              label: 'Source',
            },
            {
              id: 'status',
              label: 'Status',
            },
          ]}
          rowsData={rowData}
          showSerialNo={true}
          showCheckbox={false}
          //   setCheckedItems={setCheckedItems}
          //   checkedItems={checkedItems}
          allowSelectAll={false}
          showAddIcon={
            timeManagementPermission?.['attendance']?.[
              permissionsObj.allowAdd
            ] || false
          }
          onAddClick={() => navigate('m')}
        />
      </Box>
    </>
  );
};

export default AttendanceFormDashboard;
