export const WORK_ADD_CONFIG = 'WORK_ADD_CONFIG';

export const WORK_EDIT_MODAL_DATA = 'WORK_EDIT_MODAL_DATA';

export const WORK_DELETE_MODAL = 'WORK_DELETE_MODAL';

export const WORK_RESET_CONFIG = 'WORK_RESET_CONFIG';

export const GET_CLAIM_WORK_FORM_CONFIG_REQUEST = 'GET_CLAIM_WORK_FORM_CONFIG_REQUEST';
export const GET_CLAIM_WORK_FORM_CONFIG_SUCCESS = 'GET_CLAIM_WORK_FORM_CONFIG_SUCCESS';
export const GET_CLAIM_WORK_FORM_CONFIG_FAILURE = 'GET_CLAIM_WORK_FORM_CONFIG_FAILURE';

export const GET_ALL_WORK_FORMS_LIST_REQUEST = 'GET_ALL_WORK_FORMS_LIST_REQUEST';
export const GET_ALL_WORK_FORMS_LIST_SUCCESS = 'GET_ALL_WORK_FORMS_LIST_SUCCESS';
export const GET_ALL_WORK_FORMS_LIST_FAILURE = 'GET_ALL_WORK_FORMS_LIST_FAILURE';