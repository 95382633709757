import React, { useState } from 'react';
import FormConfigEdit from './form-config-edit.component';

const AddConfig = (props) => {
  const {
    formInfo,
    setFormInfo,
    fieldType,
    validationErrors,
    setValidationErrors,
    dataType,
    moduleId,
    list
  } = props;

  return (
    <>
      <FormConfigEdit
        formInfo={formInfo}
        setFormInfo={setFormInfo}
        fieldType={fieldType}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
        dataType={dataType}
        moduleId={moduleId}
        list={list}
      />
    </>
  );
};

export default AddConfig;
