import React, { useEffect, useState } from 'react';
import AccordionTabs from '../../../../atoms/Accordian/Accordian';
import { Box, IconButton, Typography } from '@mui/material';
import ExpressionAccordian from './expression-accordian/expression-accordian.comonent';
import { AddOutlined } from '@mui/icons-material';

const DateConditional = (props) => {
  const { setFormInfo, formula, list } = props;
  const [formulaRules, setFormulaRules] = useState(formula || [{}]);

  useEffect(() => {
    setFormInfo((prev) => ({
      ...prev,
      dateConditional: formulaRules,
    }));
  }, [formulaRules]);

  return (
    <>
      {formulaRules.map((item, index) => {
        return (
          <Box key={index} pl={15} position={'relative'}>
            <Box
              position={'absolute'}
              left={0}
              top={'5px'}
              border={'1px solid #00806E'}
              borderRadius={4}
              padding={'5px 17px 3px'}
              width="max-content"
            >
              {index === 0 ? (
                <Typography>if</Typography>
              ) : (
                <Typography>Else if</Typography>
              )}
            </Box>
            <AccordionTabs
              title={`Rule ${index + 1}`}
              bgColorSummary="rgba(167, 236, 226, 0.3)"
              tabDetails={
                <Box>
                  <ExpressionAccordian
                    setFormulaRules={setFormulaRules}
                    index={index}
                    data={item}
                    noOfRules={formulaRules.length}
          list={list}
                  />
                </Box>
              }
            />
          </Box>
        );
      })}
      <IconButton
        onClick={() =>
          setFormulaRules((prev) => {
            return [...prev, {}];
          })
        }
        sx={{
          pl: 15,
          width: 'max-content',
        }}
      >
        <AddOutlined />
      </IconButton>
    </>
  );
};

export default DateConditional;
