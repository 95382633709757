import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ModalDialog from '../../../../../../../atoms/Modal/ModalDialog';
import ClaimParentModel from './claim-parent-model.component';
import { showToast } from '../../../../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../../constants/onboarding.constants';
import {
  ToastThemes,
  toastMessage,
} from '../../../../../../../constants/common';
import { OnboardingServices } from '../../../../../../../services/onboarding';
import ClaimPreviewModal from './claim-preview-modal.component';

const ClaimModalComponent = (props) => {
  const { roleId, moduleId, roleName, content, label = 'Claims Config', claims = true, list } = props;

  const [formInfo, setFormInfo] = useState({});
  const [error, setError] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [showPrevModal, setShowPrevModal] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  useEffect(() => {
    if (showModal === true) {
      setConfirmClose(false);
    }
  }, [showModal]);

  const handleSaveClick = () => {
    if (!formInfo?.moduleId) {
      setError('Select Form can\'t be empty');
    } else {
      new OnboardingServices()
        .updateRolePermissions({ roleId, permissions: [formInfo] })
        .then(() => {
          showToast(
            ToastMessages.success.replace(
              stringSubstitute,
              toastMessage.update
            ),
            ToastThemes.success
          );
          setConfirmClose(true);
          setShowModal(false);
          setFormInfo({});
        })
        .catch(() => {
          setConfirmClose(false);
          setShowModal(true);
        });
    }
  };

  const buttonClick = async () => {
    setShowModal(true);
  };

  const prevButtonClick = async () => {
    setShowPrevModal(true);
  };

  return (
    <>
      <Box display="flex" gap={2}>
        <Button onClick={buttonClick}>{label}</Button>
        <ModalDialog
          severity="success"
          title={label}
          listComponent={
            <ClaimParentModel
              formInfo={formInfo}
              setFormInfo={setFormInfo}
              moduleId={moduleId}
              roleId={roleId}
              roleName={roleName}
              error={error}
              setError={setError}
              claims={claims}
              list = {list}
            />
          }
          primaryAction="save"
          secondaryAction="Cancel"
          showModal={showModal}
          onClickClose={() => {
            setShowModal(false);
            setConfirmClose(false);
            setFormInfo({});
          }}
          onClickConfirm={handleSaveClick}
          maxWidth="fit-content"
          fullWidth
          confirmClose={confirmClose}
          primaryButtonDisabled={error}
        />
        {claims && 
        <>
        <Button onClick={prevButtonClick}>Claims Permissions preview</Button>
        <ModalDialog
          severity="success"
          title={`Preview of Assigned Permissions (${roleName})`}
          listComponent={<ClaimPreviewModal content={content} />}
          showModal={showPrevModal}
          onClickClose={() => {
            setShowPrevModal(false);
          }}
          maxWidth="fit-content"
          fullWidth
        />
        </>
}
      </Box>
    </>
  );
};

export default ClaimModalComponent;
