import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListView from '../../../atoms/DataList/ListView';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { getAllFormsList } from '../../../redux/work-space-builder/action';
import { AppLevelPermissions } from '../../OnBoarding/Organization/constants/onboarding.constants';

const AllWorkSpaceBuilder = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [checkedItems, setCheckedItems] = useState([]);
  
    const { currentUserRoleId } = useSelector((state) => state.main);
    const { allDashboardList } = useSelector((state) => state.workSpaceBuilder) || {};
    // const [searchParams, setSearchParams] = useSearchParams();
    // const searchInput = searchParams.get('q');
    // const debouncedVal = useDebounce(searchInput, DebounceTimer);
  
    useEffect(() => {
      if (currentUserRoleId) {
        dispatch(getAllFormsList(AppLevelPermissions.TimeManagement));
      }
    }, [currentUserRoleId]);
  
    // useEffect(() => {
    //   setCheckedItems([]);
    // }, [allDashboardList]);
  
    const rowData = allDashboardList?.map((item) => {
      const { displayName, id } = item;
      return {
        name: displayName,
        action: (
          <IconButton onClick={() => navigate({ pathname: `${id}` })}>
            <InfoOutlined sx={{ color: 'primary.main80' }} />
          </IconButton>
        ),
        id: {
          hide: true,
          value: id,
        },
      };
    });
  
    return (
      <>
        {/* <CircularLoader show={listLoading === LoadingStatus.Loading} /> */}
        <ListView
          headCells={[{
            id: 'name',
            label: 'Module Name',
          }]
        }
          rowsData={rowData}
          showSerialNo={true}
          showCheckbox={false}
          // setCheckedItems={setCheckedItems}
          // checkedItems={checkedItems}
          allowSelectAll={false}
          showAddIcon={true}
          onAddClick={() => navigate('create')}
        />
      </>
    );
};

export default AllWorkSpaceBuilder;