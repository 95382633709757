import React, { useEffect, useRef, useState } from 'react';
import './SelfieAndLocationForm.css';
import { fileUpload } from '../../../../redux/main/action';
import { useDispatch, useSelector } from 'react-redux';
import ApiSelectTag from '../../../../atoms/Select/ApiSelectTag';
import {
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingStatus, ToastThemes } from '../../../../constants/common';
import { showToast } from '../../../../utils/common.util';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import { GeoTagging } from '../../../../services/geoTagging';
import { formattedDateTimeValue } from '../../../../utils/formatter';
import moment from 'moment';
import DateTimeField from '../../../../atoms/DateTimeField/DateTimeField';
import SelfieFormView from './SelfieFormView';
import { permissionsObj } from '../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import TryAgain from '../../../molecules/ErrorState/error.component';
import { isEmpty } from 'lodash';
import * as turf from '@turf/turf';

const SelfieAndLocationForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inputType = searchParams.get('t');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [locations, setLocations] = useState(null);
  const [address, setAddress] = useState(null);
  const [error, setError] = useState({ c: null, l: null });
  const [currentTime, setCurrentTime] = useState('');
  const [selfieBlobUrl, setSelfieBlobUrl] = useState(null);
  const [selfieFile, setSelfieFile] = useState(null);
  const [utcTimestamp, setUtcTimestamp] = useState('');
  const [timezone, setTimezone] = useState('');
  const [timezoneOffset, setTimezoneOffset] = useState('');
  const [punchType, setPunchType] = useState(inputType || '');
  const streamRef = useRef(null);
  const [loading, setLoading] = useState(-1);
  const [manualTime, setManualTime] = useState('');
  const [useManualTime, setUseManualTime] = useState(false);
  const [viewMode, setViewMode] = useState(true);
  const [details, setDetails] = useState({});
  const [cameraDenied, setCameraDenied] = useState(false);
  const [locationDenied, setLocationDenied] = useState(false);
  const [cameraDeniedCount, setCameraDeniedCount] = useState(0);
  const [locationDeniedCount, setLocationDeniedCount] = useState(0);
  const [geoFenceDetails, setGeoFenceDetails] = useState();
  const {
    pkId,
    fkUserId,
    locationLat,
    locationLong,
    geoLocation,
    attendanceDatetimeUtc,
    attendanceInputCode,
    attendanceInOutDirection,
    attendanceDatetimeTimezone,
    attendanceDatetimeTzOffset,
    createdUtc,
    userImageUrl,
    schedule,
    otHours,
  } = details || {};

  useEffect(() => {
      setUseManualTime(attendanceInputCode === 'M' || false);
      setManualTime(attendanceDatetimeUtc);
      setPunchType(attendanceInOutDirection || inputType);
      setSelfieBlobUrl(userImageUrl);
      setLocations({ latitude: locationLat, longitude: locationLong });
  }, [details]);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo: loggedInUserDetails, currentUserRoleId, isMobile } = useSelector(
    (state) => state.main
  );
  const { timeManagementPermission } = useSelector(
    (state) => state.modulePermissions
  );

  const pathNames = location.pathname.split('/');
  const idForDetails = pathNames[pathNames.length - 1];
  const permissions =
    timeManagementPermission?.['attendance']?.[permissionsObj.allowAdd];
  const viewPermission =
    timeManagementPermission?.['attendance']?.[permissionsObj.allowView];
  // const fromLib = location.state;

  useEffect(async () => {
    if (currentUserRoleId) {
      if (!isNaN(idForDetails)) {
        setLoading(LoadingStatus.Loading);
        await new GeoTagging()
          .details(currentUserRoleId, idForDetails)
          .then((res) => {
            setDetails(res);
            setLoading(LoadingStatus.Success);
            setViewMode(true);
          })
          .catch((err) => {
            setLoading(LoadingStatus.Failure);
            showToast(
              err?.response?.data?.message || 'Something Went Wrong! Try Again',
              ToastThemes.error
            );
            throw err?.response?.data?.message;
          });
      } else {
        setViewMode(false);
      }
    }
    return () => {
      setViewMode(false);
    };
  }, [idForDetails, currentUserRoleId]);

  useEffect(async () => {
    if (!viewMode) {
      setLoading(LoadingStatus.Loading);
      await new GeoTagging()
        .geoFencingDetails()
        .then((res) => {
          setGeoFenceDetails(res);
          setLoading(LoadingStatus.Success);
        })
        .catch((err) => {
          setLoading(LoadingStatus.Failure);
          showToast(
            err?.response?.data?.message || 'Something Went Wrong! Try Again',
            ToastThemes.error
          );
          throw err?.response?.data?.message;
        });
    }
  }, [viewMode]);

  useEffect(() => {
    if (viewMode) return; // If viewMode is true, don't run the following logic

    const startLocationAndCamera = async () => {
      isEmpty(details) && getLocation();
      startCamera();
    };

    const handleVisibilityChange = () => {
      if (document.hidden || viewMode) {
        stopCamera();
      } else {
        startCamera();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    if (!viewMode) {
      startLocationAndCamera();
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      stopCamera();
    };
  }, [viewMode]);

  useEffect(() => {
    if (!useManualTime) {
      const interval = setInterval(() => {
        const now = new Date();

        const utcTime = now.toISOString();

        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const offsetMinutes = now.getTimezoneOffset();
        const offsetHours = Math.abs(Math.floor(Math.abs(offsetMinutes) / 60));
        const offsetMins = Math.abs(offsetMinutes % 60);

        const sign = offsetMinutes <= 0 ? '+' : '-';

        const formattedOffset = `UTC${sign}${String(offsetHours).padStart(
          2,
          '0'
        )}:${String(offsetMins).padStart(2, '0')}`;

        setTimezoneOffset(formattedOffset);
        setCurrentTime(now.toLocaleString());
        setUtcTimestamp(utcTime);
        setTimezone(userTimezone);
        getLocation();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [useManualTime]);

  useEffect(() => {
    if (viewMode) {
      stopCamera();
    }
  }, [viewMode]);

  const GOOGLE_API_KEY = 'YOUR_GOOGLE_API_KEY'; // Replace with your API key

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraActive(true);
      setCameraDenied(false);
      setCameraDeniedCount(0);
      setError((prev) => ({ ...prev, c: null }));
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      setCameraDenied(true);
      setCameraDeniedCount((prevCount) => prevCount + 1); // Increment denial count
      setError((prev) => ({ ...prev, c: 'Unable to access the camera.' }));
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
    setCameraActive(false);
  };

  const captureSelfie = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext('2d');
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      context.drawImage(videoRef.current, 0, 0);

      canvasRef.current.toBlob((blob) => {
        if (selfieBlobUrl) {
          URL.revokeObjectURL(selfieBlobUrl);
        }

        const filename = `${crypto.randomUUID()}.png`;

        const file = new File([blob], filename, {
          type: 'image/png',
          lastModified: Date.now(),
        });

        const blobUrl = URL.createObjectURL(file);
        setSelfieBlobUrl(blobUrl);
        setSelfieFile(file);
      }, 'image/png');
    }
  };

  const deleteSelfie = () => {
    if (selfieBlobUrl) {
      URL.revokeObjectURL(selfieBlobUrl);
    }
    setSelfieBlobUrl(null);
    setSelfieFile(null);
    startCamera();
  };

  const getLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocations({ latitude, longitude });
          await getAddress(latitude, longitude);
          setLocationDenied(false);
          setLocationDeniedCount(0);
          setError((prev) => ({ ...prev, l: null }));
        },
        () => {
          setError((prev) => ({ ...prev, l: 'Unable to access location.' }));
          setLocationDeniedCount((prevCount) => prevCount + 1);
          setLocationDenied(true); // Set location denial state if permission is denied
        },
        { enableHighAccuracy: true }
      );
    } else {
      setError((prev) => ({
        ...prev,
        l: 'Geolocation is not supported by your browser.',
      }));
    }
  };

  const requestLocationPermission = () => {
    getLocation();
  };

  const requestCameraPermission = async () => {
    startCamera();
  };

  // Convert latitude and longitude into an address
  const getAddress = async (latitude, longitude) => {
    // try {
    //   const response = await fetch(
    //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`
    //   );
    //   const data = await response.json();
    //   if (data.status === 'OK' && data.results.length > 0) {
    //     setAddress(data.results[0].formatted_address);
    //   } else {
    //     setError('Unable to fetch address.');
    //   }
    // } catch (err) {
    //   setError('Error fetching address.');
    // }
  };

  const geoFencing = async (e) => {
    e.preventDefault();

    if (geoFenceDetails?.geofenceCoordinates && geoFenceDetails?.geofenceCoordinates?.geofenceCoordinates) {
      const isInside = Geofencing(
        geoFenceDetails?.geofenceCoordinates?.geofenceType,
        geoFenceDetails?.geofenceCoordinates?.geofenceCoordinates?.features[0]
          ?.geometry?.coordinates,
        geoFenceDetails?.geofenceCoordinates?.geofenceCoordinates?.features[0]
          ?.properties?.radius,
        locations
      );
      if (typeof isInside === 'string') {
        showToast(isInside, ToastThemes.error);
        return;
      }
      if (!isInside) {
        showToast('You are outside the permitted area.', ToastThemes.error);
        return;
      }
    }

    await handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selfieFile && !selfieBlobUrl) {
      showToast('Please Capture your Selfie/Image', ToastThemes.error);
      return;
    }
    if (!locations) {
      showToast('Please Allow your Location', ToastThemes.error);
      return;
    }
    if (!punchType) {
      showToast('Please Select your Punch Type', ToastThemes.error);
      return;
    }
    setLoading(LoadingStatus.Loading);

    const attendanceData = {
      fkUserId: loggedInUserDetails?.id, // User ID
      attendanceDatetimeUtc: useManualTime ? manualTime : utcTimestamp,
      attendanceDatetimeTzOffset: timezoneOffset || attendanceDatetimeTzOffset, // Timezone offset (e.g., +05.30)
      attendanceDatetimeTimezone: timezone || attendanceDatetimeTimezone, // Timezone string (e.g., Asia/Kolkata)
      attendanceInOutDirection: punchType?.id || punchType, // 'I' for In, 'O' for Out
      locationLat: locations?.latitude, // Latitude
      locationLong: locations?.longitude, // Longitude
      geoLocation: address, // Geolocation (Address)
      attendanceInputCode: useManualTime ? 'M' : 'A',
    };

    if (pkId) {
      attendanceData.pkId = pkId;
      attendanceData.otHours = otHours;
    }

    if (selfieFile) {
      await dispatch(fileUpload(selfieFile)).then((res) => {
        attendanceData.userImageUrl = res.url;
      });
    }

    await new GeoTagging()
      .postAttendance(attendanceData)
      .then((res) => {
        setLoading(LoadingStatus.Success);
        showToast('Attendance Marked!', ToastThemes.success);
        setTimeout(() => {
          navigate(isMobile? '/home' : '/time-management/attendance');
        }, 1500);
      })
      .catch((err) => {
        setLoading(LoadingStatus.Failure);
        showToast(
          err?.response?.data?.message || 'Something Went Wrong! Try Again',
          ToastThemes.error
        );
        throw err?.response?.data?.message;
      });
  };

  const handleDiscard = () => {
    navigate(-1);
  };

  const handleTimeToggle = () => {
    setUseManualTime((prev) => !prev);
  };

  const handleDateTimeChange = (value, name) => {
    const formattedVal = formattedDateTimeValue(value);
    setManualTime(value ? formattedVal : '');
  };
  if (viewMode && viewPermission) {
    return (
      <div>
        <CircularLoader show={LoadingStatus.Loading === loading} />
        <SelfieFormView data={details} setViewMode={setViewMode} />
      </div>
    );
  }
  if (!permissions) {
    return null;
  }
  return (
    <div className="form-container">
      <CircularLoader show={LoadingStatus.Loading === loading} />
      <FormControlLabel
        control={<Switch checked={useManualTime} onChange={handleTimeToggle} />}
        label="Select Manual Date/Time"
      />
      <div className="section" style={{ marginTop: '8px' }}>
        {locations && (
          <Typography variant="subtitle2" color={'neutral.dark'}>
            Current Location: {locations.latitude}, {locations.longitude}
          </Typography>
        )}
        {address && <p>Address: {address}</p>}
      </div>
      {!useManualTime ? (
        <div className="date-time">
          <Typography variant="subtitle2">
            Current Date and Time: {currentTime}
          </Typography>
        </div>
      ) : (
        <DateTimeField
          labelText={'Select Date Time'}
          fullWidth
          onChange={handleDateTimeChange}
          value={
            manualTime
              ? moment(manualTime, 'YYYY-MM-DD HH:mm')
                  .utc(manualTime, 'YYYY-MM-DD HH:mm')
                  .local()
                  .format('DD/MM/YYYY hh:mm a')
              : null
          }
          placeholder={'DD/MM/YYYY hh:mm a'}
          dataInputFormat={'DD/MM/YYYY hh:mm a'}
          disableFuture={true}
        />
      )}
      <form onSubmit={geoFencing}>
        <FormControl fullWidth margin="normal" required>
          <ApiSelectTag
            defaultValue={'Punch Type'}
            fullWidth
            // required={true}
            dropDownList={[
              { id: 'I', name: 'Punch In' },
              { id: 'O', name: 'Punch Out' },
            ]}
            name="punchType"
            value={punchType}
            onchange={(e) => setPunchType(e.target.value)}
            size="small"
          />
        </FormControl>
        <div className="section">
          {!cameraActive ? (
            <button type="button" onClick={startCamera}>
              Start Camera
            </button>
          ) : (
            <div className="camera-container">
              {!selfieBlobUrl && (
                <>
                  <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    className="video"
                  />
                  <canvas ref={canvasRef} style={{ display: 'none' }} />
                  <div className="button-group">
                    <Button
                      type="button"
                      onClick={captureSelfie}
                      variant="contained"
                      style={{ backgroundColor: '#007bff' }}
                    >
                      Capture Image
                    </Button>
                  </div>
                </>
              )}
              {selfieBlobUrl && (
                <div className="selfie-container">
                  <img
                    src={selfieBlobUrl}
                    alt="Selfie"
                    className="selfie-image"
                  />
                  <div className="button-group">
                    <Button
                      type="button"
                      onClick={deleteSelfie}
                      variant="contained"
                      color="error"
                    >
                      Delete Image
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {error.c && cameraDenied && cameraDeniedCount < 2 && (
          <p className="error">{error.c}</p>
        )}
        {cameraDenied && cameraDeniedCount < 2 && (
          <button type="button" onClick={requestCameraPermission}>
            Request Camera Permission
          </button>
        )}
        {cameraDenied && cameraDeniedCount >= 2 && (
          <div>
            <p className="error">
              Camera permission has been denied multiple times. Please go to
              your browser settings and allow camera access for this site.
            </p>
          </div>
        )}
        {error.l && locationDenied && locationDeniedCount < 2 && (
          <p className="error">{error.l}</p>
        )}
        {/* Location Denied Handling */}
        {locationDenied && locationDeniedCount < 2 && (
          <button type="button" onClick={requestLocationPermission}>
            Request Location Permission
          </button>
        )}
        {locationDenied && locationDeniedCount >= 2 && (
          <div>
            <p className="error">
              Location permission has been denied multiple times. Please go to
              your browser settings and allow location access for this site.
            </p>
          </div>
        )}
        <div className="button-group">
          <Button
            type="submit"
            className="submit-button"
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            type="button"
            className="discard-button"
            onClick={handleDiscard}
            variant="contained"
            color="error"
          >
            Discard
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SelfieAndLocationForm;

const Geofencing = (type, value, radius, location) => {
  if (location.latitude && location.longitude) {
    const userPoint = turf.point([location.longitude, location.latitude]);
    let inside = false;

    switch (type) {
      case 'C': {
        const circularCenter = turf.point(value);
        const circularRadius = radius || 1;
        const circularGeofence = turf.buffer(circularCenter, circularRadius, {
          units: 'kilometers',
        });
        inside = turf.booleanPointInPolygon(userPoint, circularGeofence);
        break;
      }
      case 'P': {
        const polygonGeofence = turf.polygon(value);
        inside = turf.booleanPointInPolygon(userPoint, polygonGeofence);
        break;
      }
      case 'L': {
        const lineStringGeofence = turf.lineString(value);

        const distance = turf.pointToLineDistance(
          userPoint,
          lineStringGeofence,
          { units: 'kilometers' }
        );
        inside = distance <= 0.05; // Within 50 meters of the line
        break;
      }
      default:
        inside = false;
    }
    return inside;
  }
};
