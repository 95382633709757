import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import {
  WORK_ADD_CONFIG,
  WORK_DELETE_MODAL,
  WORK_EDIT_MODAL_DATA,
  GET_ALL_WORK_FORMS_LIST_FAILURE,
  GET_ALL_WORK_FORMS_LIST_REQUEST,
  GET_ALL_WORK_FORMS_LIST_SUCCESS,
  GET_CLAIM_WORK_FORM_CONFIG_FAILURE,
  GET_CLAIM_WORK_FORM_CONFIG_REQUEST,
  GET_CLAIM_WORK_FORM_CONFIG_SUCCESS,
  WORK_RESET_CONFIG,
} from './actionTypes';

const initialState = {
  list: [],
  data: {},
  allDashboardList: [],
};

export const workSpaceBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case WORK_ADD_CONFIG: {
      const formConfig = action.payload;
      return {
        ...state,
        list: sortedArray([...state.list, formConfig]),
      };
    }

    case WORK_EDIT_MODAL_DATA: {
      const { index, data } = action.payload;
      const newList = [...state.list];
      newList[index] = { ...data };
      return {
        ...state,
        list: sortedArray(newList),
      };
    }

    case WORK_DELETE_MODAL: {
      const index = action.payload;
      const newList = [...state.list];
      newList.splice(index, 1);
      return {
        ...state,
        list: sortedArray(newList),
      };
    }

    

    case GET_CLAIM_WORK_FORM_CONFIG_REQUEST: {
      return {
        ...state,
        // requestProcessing: {
        //   ...state.requestProcessing,
        //   [APIRequestStatus.GET_CLAIM_FORM_CONFIG]: LoadingStatus.Loading,
        // },
      };
    }

    case GET_CLAIM_WORK_FORM_CONFIG_SUCCESS: {
      const  {data, formConfig} = action.payload;
      const newConfig = formConfig.map((item) => {
        if (item.minMinutesNextClaim) {
          item.errorMessage = item.minMinutesNextClaim.errorMessage || null;
          item.timeInMinutes = item.minMinutesNextClaim.timeInMinutes || null;
        }
        if (item.validationOption) {
          item.errorMessageValidationOptions =
            item.validationOption.errorMessageValidationOptions || null;
          item.option = item.validationOption.option || null;
        }
        delete item.minMinutesNextClaim;
        delete item.validationOption;
        return item;
      });
      return {
        ...state,
        list: newConfig,
        data: data,
        // requestProcessing: {
        //   ...state.requestProcessing,
        //   [APIRequestStatus.GET_CLAIM_FORM_CONFIG]: LoadingStatus.Success,
        // },
      };
    }

    case GET_CLAIM_WORK_FORM_CONFIG_FAILURE: {
      return {
        ...state,
        // requestProcessing: {
        //   ...state.requestProcessing,
        //   [APIRequestStatus.GET_CLAIM_FORM_CONFIG]: LoadingStatus.Failure,
        // },
      };
    }

    case GET_ALL_WORK_FORMS_LIST_REQUEST: {
      return {
        ...state,
        // requestProcessing: {
        //   ...state.requestProcessing,
        //   [APIRequestStatus.GET_ALL_FORMS_LIST]: LoadingStatus.Loading,
        // },
      };
    }

    case GET_ALL_WORK_FORMS_LIST_SUCCESS: {
      const res = action.payload;
      return {
        ...state,
        allDashboardList: res,
      };
    }

    case GET_ALL_WORK_FORMS_LIST_FAILURE: {
      return {
        // ...state,
        // requestProcessing: {
        //   ...state.requestProcessing,
        //   [APIRequestStatus.GET_ALL_FORMS_LIST]: LoadingStatus.Failure,
        // },
      };
    }

    case WORK_RESET_CONFIG: {
      return initialState;
    }

    
    default: {
      return state;
    }
  }
};

const sortedArray = (array = []) => {
  return array.sort(function (a, b) {
    if (Number(a.positionOrder) < Number(b.positionOrder)) return -1;
    if (Number(a.positionOrder) > Number(b.positionOrder)) return 1;
    return 0;
  });
};
