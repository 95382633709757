import { Box, Grid } from '@mui/material';
import React, { useState, forwardRef } from 'react';
import AccordionTabs from '../../../../../atoms/Accordian/Accordian';
import { GoalFormHeaders } from '../../common/constants';
import KRAForm from './KRAForm/KRAForm';
import { useRef } from 'react';
import useDidMountEffect from '../../../../../customHooks/useDidMountEffect';
import { KRADropdownEndPoints, KRAFormFieldNames } from '../../../Utlis/goals.util';
import { GoalsServices } from '../../../../../services/goals';

const KRATab = (props) => {
  const { formConfig, formData, KRATabRef, ratingScaleKra, kraValidationErrors, setKraValidationErrors,
    kraErrorIndex, setKraErrorTabIndex } = props;

  const bottomKraRef = useRef(null);
  useDidMountEffect(() => {
    if (formConfig.length) {
      bottomKraRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formConfig.length]);
  const [kraTypeDropDownOptions, setKraTypeDropdownOptions] = useState([]);
  const [kraCategoryDropDownOptions, setKraCategoryDropdownOptions] = useState([]);
  const [measureDropDownOptions, setMeasureDropdownOptions] = useState([]);
  const [kpiDropDownOptions, setKpiDropdownOptions] = useState([]);

  const handleDropdownClick = (name) => {
    switch (name) {
      case KRAFormFieldNames.Category: {
        return new GoalsServices().getDropdownOptions(KRADropdownEndPoints.Category).then((res) => {
          setKraCategoryDropdownOptions(res);
          return true;
        });
      }
      case KRAFormFieldNames.Type: {
        return new GoalsServices().getDropdownOptions(KRADropdownEndPoints.Type).then((res) => {
          setKraTypeDropdownOptions(res);
          return true;
        });
      }
      case KRAFormFieldNames.Kpi: {
        return new GoalsServices().getDropdownOptions(KRADropdownEndPoints.Kpi).then((res) => {
          setKpiDropdownOptions(res);
          return true;
        });
      }
      case KRAFormFieldNames.Measure: {
        return new GoalsServices().getDropdownOptions(KRADropdownEndPoints.Measure).then((res) => {
          setMeasureDropdownOptions(res);
          return true;
        });
      }
      default: {
        break;
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case KRAFormFieldNames.Type: {
        return kraTypeDropDownOptions;
      }
      case KRAFormFieldNames.Category: {
        return kraCategoryDropDownOptions;
      }
      case KRAFormFieldNames.Kpi: {
        return kpiDropDownOptions;
      }
      case KRAFormFieldNames.Measure: {
        return measureDropDownOptions;
      }
    }
  };

  return (
    <Grid item md={11}>
      {formConfig.map((kraFormConfig, index) => (
        <div key={index}
          ref={index === formConfig.length - 1 ? bottomKraRef : null}
        >
          <AccordionTabs
            key={index}
            title={`${kraFormConfig?.[0]?.fieldLabel} ${index + 1}`}
            tabDetails={
              <Box>
                <KRAForm
                  ref={KRATabRef}
                  formConfig={kraFormConfig}
                  tabIndex={index}
                  formData={formData[index]}
                  handleDropdownClick={handleDropdownClick}
                  getDropdownOptions={getDropdownOptions}
                  numberOfKras={formConfig.length}
                  ratingScaleKra={ratingScaleKra}
                  kraValidationErrors={kraValidationErrors[index]}
                  setKraValidationErrors={setKraValidationErrors}
                  kraErrorIndex={kraErrorIndex}
                  setKraErrorTabIndex={setKraErrorTabIndex}
                />
              </Box>
            }
          />
        </div>
      ))}
    </Grid>
  );
};

export default React.memo(forwardRef(KRATab));
