import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, FormControl, MenuItem } from '@mui/material';
import SearchBar from '../../../atoms/SearchBar/SearchBar';
import { StyledSelect } from './SubHeader.style';
import { useSelector } from 'react-redux';
import { LightTooltip } from '../../../atoms/Tooltip/LightTooltip';
import { AppLevelPermissions } from '../../OnBoarding/Organization/constants/onboarding.constants';
import theme from '../../../foundation/theme';

const SubHeader = (props) => {
  const { tabsData } = props;
  const [value, setValue] = useState([]);
  const navigate = useNavigate();
  const { appPermissions } = useSelector((state) => state.main);

  const handleChange = (event) => {
    navigate(`/time-management/${event.target.value}`);
  };

  return (
    <Box sx={{ display: 'flex', gap: 7 }}>
      <FormControl variant="standard" sx={{ width: 150, border: 0, mt: 1 }}>
        <StyledSelect
          displayEmpty
          value={value}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected) {
              return (
                <LightTooltip
                  title={
                    appPermissions[AppLevelPermissions.TimeManagement]
                      ?.displayName
                  }
                  placement="bottom-end"
                  arrow
                  bgColor="#212B36"
                  color={theme.palette.neutral.light60}
                  sx={{ margin: 2 }}
                  enterDelay={500}
                >
                  {
                    appPermissions[AppLevelPermissions.TimeManagement]
                      ?.displayName
                  }
                </LightTooltip>
              );
            }
          }}
        >
          {tabsData.map((item) => {
            const { id, name } = item;
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </FormControl>

      <SearchBar />
    </Box>
  );
};

export default SubHeader;