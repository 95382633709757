import API from './api';
export class PayrollAssignment extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/payroll/pay-structure/' || '';
  constructor() {
    super(PayrollAssignment.baseURL);
  }

  getComponentsConfig = async (salaryStructureId) => (
    this.responsify(await this.instance.get(`components/${salaryStructureId}`))
  );

  getComponentsDetails = async (payStructureId, employeeId, isUpcoming) => (
    this.responsify(await this.instance.get('employee', { params: { payStructureId, employeeId, isUpcoming } }))
  );

  postComponentsInfo = async (body) => (
    this.responsify(await this.instance.post('assign-pay-structure', body))
  );

  updateComponentsInfo = async (body) => (
    this.responsify(await this.instance.put('employee/update', body))
  );

  getListAllComponent = async (roleId, searchQuery) => (
    this.responsify(await this.instance.get('components/list',{params:{roleId, searchQuery}}))
  );

  postComponent = async (formData, roleId) => (
    this.responsify(await this.instance.post('components/create', formData, {params: {roleId}}))
  );

  getComponentDetail = async (id) => (
    this.responsify(await this.instance.get(`components/details/${id}`))
  );

  getComponentDropDown = async (name) => (
    this.responsify(await this.instance.get(`components/drop-down/${name}`))
  );
}