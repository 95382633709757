import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import LeaveViewForm from './leave-view-form';
import LeaveEditForm from './leave-edit-form';
import { GeoTagging } from '../../../../../services/geoTagging';
import { LoadingStatus, ToastThemes } from '../../../../../constants/common';
import { showToast } from '../../../../../utils/common.util';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import TryAgain from '../../../../molecules/ErrorState/error.component';

const AddLeaveForm = (props) => {
  const { viewMode, setViewMode, setData } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const paths = location.pathname.split('/');
  const moduleId = Number(paths[paths.length - 2]);
  const idForDetails = Number(paths[paths.length - 1]);
  const [isEditable, setIsEditable] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [formInfo, setFormInfo] = useState({});
  const [loading, setLoading] = useState(-1);
  const {
    info,
    formConfig,
    auditTrail = [],
    allowEdit = false,
    permissions,
    approvalRemarksPermission,
  } = formInfo || {};

  const { data, formConfig: list } = formConfig || {};
  const { currentUserRoleId } = useSelector((state) => state.main);

  useEffect(async () => {
    if (currentUserRoleId) {
      if (!isNaN(idForDetails)) {
        setLoading(LoadingStatus.Loading);
        new GeoTagging()
          .getWorkPlaceLeaveDetails(moduleId, idForDetails, currentUserRoleId)
          .then((res) => {
            setFormInfo(res);
            setLoading(LoadingStatus.Success);
          })
          .catch((err) => {
            setLoading(LoadingStatus.Failure);
            showToast(err?.response?.data?.message, ToastThemes.error);
            throw err?.response?.data?.message;
          });
        setViewMode(true);
      } else {
        setLoading(LoadingStatus.Loading);
        new GeoTagging()
          .getWorkPlaceLeaveDetails(moduleId, '', currentUserRoleId)
          .then((res) => {
            setFormInfo(res);
            setLoading(LoadingStatus.Success);
          })
          .catch((err) => {
            setLoading(LoadingStatus.Failure);
            showToast(err?.response?.data?.message, ToastThemes.error);
            throw err?.response?.data?.message;
          });
      }
    }
    return () => {
      setViewMode(false);
    };
  }, [moduleId, currentUserRoleId, idForDetails]);

  useEffect(() => {
    setData(data);
  }, [data]);

  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      {loading === LoadingStatus.Success && viewMode ? (
        <LeaveViewForm
          claimTypeId={moduleId}
          claimId={idForDetails}
          claimFormData={info}
          formConfig={list}
          setViewMode={setViewMode}
          claimName={data.name}
          moduleId={moduleId}
          auditTrail={auditTrail}
          allowEdit={allowEdit}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          permissions={permissions}
          setValidationErrors={setValidationErrors}
          approvalRemarksPermission={approvalRemarksPermission}
        />
      ) : (
        <LeaveEditForm
          formConfig={list}
          claimTypeData={data}
          claimFormData={info}
          setViewMode={setViewMode}
          claimTypeId={moduleId}
          claimId={idForDetails}
          resubmittedClaim={isEditable}
          allowEdit={allowEdit}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
        />
      )}
    </>
  );
};

export default AddLeaveForm;
