import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { ComponentInputType } from '../../constants/common';
import { PayrollAssignment } from '../../services/payrollAssignment';
import { currentDate } from '../../utils/formatter';
import { GET_COMPONENTS_CONFIG_FAILURE, GET_COMPONENTS_CONFIG_REQUEST, GET_COMPONENTS_CONFIG_SUCCESS, GET_COMPONENTS_DETAILS_FAILURE, GET_COMPONENTS_DETAILS_REQUEST, GET_COMPONENTS_DETAILS_SUCCESS, GET_UPCOMING_COMPONENTS_DETAILS_FAILURE, GET_UPCOMING_COMPONENTS_DETAILS_REQUEST, GET_UPCOMING_COMPONENTS_DETAILS_SUCCESS, POST_COMPONENTS_INFO_FAILURE, POST_COMPONENTS_INFO_REQUEST, POST_COMPONENTS_INFO_SUCCESS, RESET_COMPONENTS, RESET_COMPONENTS_ON_STRUCTURE_CHANGE, UPDATE_COMPONENTS_INFO_FAILURE, UPDATE_COMPONENTS_INFO_REQUEST, UPDATE_COMPONENTS_INFO_SUCCESS } from './actionTypes';


const getComponentsFormConfigRequest = () => (
  {
    type: GET_COMPONENTS_CONFIG_REQUEST
  }
);

const getComponentsFormConfigSuccess = (payload) => (
  {
    type: GET_COMPONENTS_CONFIG_SUCCESS,
    payload
  }
);

const getComponentsFormConfigFailure = () => (
  {
    type: GET_COMPONENTS_CONFIG_FAILURE
  }
);

const getComponentsFormDetailsRequest = () => (
  {
    type: GET_COMPONENTS_DETAILS_REQUEST
  }
);

const getComponentsFormDetailsSuccess = (payload) => (
  {
    type: GET_COMPONENTS_DETAILS_SUCCESS,
    payload
  }
);

const getComponentsFormDetailsFailure = () => (
  {
    type: GET_COMPONENTS_DETAILS_FAILURE
  }
);

export const resetComponents = () => (
  {
    type: RESET_COMPONENTS
  }
);

export const resetComponentsOnStructureChange = () => (
  {
    type: RESET_COMPONENTS_ON_STRUCTURE_CHANGE
  }
);

const postComponentsInfoRequest = () => (
  {
    type: POST_COMPONENTS_INFO_REQUEST
  }
);

const postComponentsInfoSuccess = (payload) => (
  {
    type: POST_COMPONENTS_INFO_SUCCESS,
    payload
  }
);

const postComponentsInfoFailure = () => (
  {
    type: POST_COMPONENTS_INFO_FAILURE
  }
);

const updateComponentsInfoRequest = () => (
  {
    type: UPDATE_COMPONENTS_INFO_REQUEST
  }
);

const updateComponentsInfoSuccess = (payload) => (
  {
    type: UPDATE_COMPONENTS_INFO_SUCCESS,
    payload
  }
);

const updateComponentsInfoFailure = () => (
  {
    type: UPDATE_COMPONENTS_INFO_FAILURE
  }
);

const getUpcomingComponentsFormDetailsRequest = () => (
  {
    type: GET_UPCOMING_COMPONENTS_DETAILS_REQUEST
  }
);

const getUpcomingComponentsFormDetailsSuccess = (payload) => (
  {
    type: GET_UPCOMING_COMPONENTS_DETAILS_SUCCESS,
    payload
  }
);

const getUpcomingComponentsFormDetailsFailure = () => (
  {
    type: GET_UPCOMING_COMPONENTS_DETAILS_FAILURE
  }
);

export const getComponentsConfig = (salaryStructureId) => async (dispatch) => {
  dispatch(getComponentsFormConfigRequest());
  new PayrollAssignment().getComponentsConfig(salaryStructureId).then((res) => {
    dispatch(getComponentsFormConfigSuccess(res));
  }).catch((err) => {
    dispatch(getComponentsFormConfigFailure());
  });
};

export const getComponentsDetails = (payStructureId, userId) => async (dispatch) => {
  dispatch(getComponentsFormDetailsRequest());
  new PayrollAssignment().getComponentsDetails(payStructureId, userId).then((res) => {
    dispatch(getComponentsFormDetailsSuccess(res));
  }).catch((err) => {
    console.log(err);
    dispatch(getComponentsFormDetailsFailure());
  });
};

export const postComponentsInfo = (additionComponent, deductionComponent, salaryType, payStrInfo, userId) =>
  async (dispatch) => {
    dispatch(postComponentsInfoRequest());
    const payStructure = getFormBody(payStrInfo);
    payStructure.effectiveDate = payStructure.effectiveDate.split('T')[0];
    const isCurrentAssigned = payStructure.effectiveDate === currentDate.startOf('day').format('YYYY-MM-DD');
    const { salaryTypeRateAmt } = salaryType;
    const additionComponents = additionComponent.filter((item) => item.inputSourceCode === ComponentInputType.Onetime);
    const deductionComponents = deductionComponent.filter((item) => item.inputSourceCode === ComponentInputType.Onetime);
    return new PayrollAssignment().postComponentsInfo({ additionComponents, deductionComponents, salaryTypeRateAmt, ...payStructure, userId, isCurrentAssigned }).then(() => {
      dispatch(postComponentsInfoSuccess());
      return true;
    }).catch((err) => {
      dispatch(postComponentsInfoFailure());
      throw err?.response?.data?.message || 'Error Saving';
    });
  };

export const updateComponentsInfo = (additionComponent, deductionComponent, salaryType, payStrInfo, userId) =>
  async (dispatch) => {
    dispatch(updateComponentsInfoRequest());
    const payStructure = getFormBody(payStrInfo);
    payStructure.effectiveDate = payStructure.effectiveDate.split('T')[0];
    const isCurrentAssigned = payStructure.effectiveDate === currentDate.startOf('day').format('YYYY-MM-DD');
    const { salaryTypeRateAmt } = salaryType;
    const additionComponents = additionComponent.filter((item) => item.inputSourceCode === ComponentInputType.Onetime);
    const deductionComponents = deductionComponent.filter((item) => item.inputSourceCode === ComponentInputType.Onetime);
    return new PayrollAssignment().updateComponentsInfo({ additionComponents, deductionComponents, salaryTypeRateAmt, ...payStructure, userId, isCurrentAssigned }).then(() => {
      dispatch(updateComponentsInfoSuccess());
      return true;
    }).catch((err) => {
      dispatch(updateComponentsInfoFailure());
      throw err?.response?.data?.message || 'Error Saving';
    });
  };

  export const getUpcomingComponentsDetails = (payStructureId, userId) => async (dispatch) => {
    dispatch(getUpcomingComponentsFormDetailsRequest());
    new PayrollAssignment().getComponentsDetails(payStructureId, userId, true).then((res) => {
      dispatch(getUpcomingComponentsFormDetailsSuccess(res));
    }).catch((err) => {
    console.log(err);
      dispatch(getUpcomingComponentsFormDetailsFailure());
    });
  };