import API from './api';

export class BuilderServices extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/claims/form-ui/' || '';
  constructor() {
    super(BuilderServices.baseURL);
  }

  getFieldRefName = async (fieldType, moduleId) =>
    this.responsify(
      await this.instance.get('sys-fields', { params: { fieldType, moduleId } })
    );

  postConfigInfo = async (body) =>
    this.responsify(await this.instance.post('create-claim', body));

  getDetails = async (id) =>
    this.responsify(await this.instance.get(`details/${id}`));

  getDropDownOption = async (id, builderCall = true) =>
    this.responsify(
      await this.instance.get(`drop-down/${id}`, { params: { builderCall } })
    );

  getDropDownBenifits = async () =>
    this.responsify(await this.instance.get('drop-down-benifits'));

  getAllFormsList = async (roleId, searchQuery) =>
    this.responsify(
      await this.instance.get('list', { params: { roleId, searchQuery } })
    );

  postClaimInfo = async (body) =>
    this.responsify(await this.instance.post('add-claim', body));

  statusUpdate = async (body) =>
    this.responsify(await this.instance.post('status-update-claim', body));

  bulkStatusUpdate = async (body) =>
    this.responsify(await this.instance.post('bulk-status-update-claim', body));

  getAllClaimsList = async (id, roleId, searchParam, startDate, endDate) =>
    this.responsify(
      await this.instance.get(`claim-list/${id}`, {
        params: { roleId, searchParam, startDate, endDate },
      })
    );

  getClaimsDetails = async (ctId, currentUserRoleId, id) =>
    this.responsify(
      await this.instance.get('claim-info', {
        params: { claimTypeId: ctId, claimId: id, currentUserRoleId },
      })
    );

  postSavedClaim = async (body) =>
    this.responsify(await this.instance.post('save-claim', body));

  getEntitlements = async (benefitId, claimTypeId, claimId) =>
    this.responsify(
      await this.instance.get('get-entitlement', {
        params: { benefitId: benefitId, claimTypeId, claimId },
      })
    );

  bulkApproveOrRejectApprovalWorflow = async (
    records,
    moduleId,
    action,
    remarks,
    reasons,
    currentUserRoleId,
    claimTypeId
  ) =>
    this.responsify(
      await this.instance.post('bulk-approver-or-reject', {
        records,
        moduleId,
        action,
        remarks,
        reasons,
        roleId: currentUserRoleId,
        claimTypeId,
      })
    );

  teamBuildingDeductions = async (receiptAmount, attendees, benifitId) =>
    this.responsify(
      await this.instance.post('team-building-deductions', {
        receiptAmount,
        attendees,
        benifitId,
      })
    );

  getTeamBuildingDetails = async (claimId) =>
    this.responsify(
      await this.instance.get('team-building-details', {
        params: { claimId },
      })
    );

  userBasedValue = async (body) =>
    this.responsify(await this.instance.post('user-based-info', body));

  currencyExchangeRate = async (currencyName, filterDate) =>
    this.responsify(
      await this.instance.get('get-currency-exchange-rate', {
        params: { currencyName, filterDate },
      })
    );

  postProRataFactor = async (body) =>
    this.responsify(await this.instance.post('pro-rate-factor', body));

  teamBuildingDeductionsView = async (forInfo, claimId) =>
    this.responsify(
      await this.instance.post(
        'team-building-deductions',
        {},
        { params: { forInfo, claimId } }
      )
    );

  getMultiClaimsDetailsTRF = async (claimIds) =>
    this.responsify(
      await this.instance.get('multi-claims-details', {
        params: { claimIds },
      })
    );
}
