import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid
} from '@mui/material';
import AccordionTabs from '../../../atoms/Accordian/Accordian';
import ActionHeader from '../../OnBoarding/Organization/ActionHeader/ActionHeader';
import FormView from '../../OnBoarding/common/Form/FormView';
import KRAFormView from './KRA/KRATab/KRAForm/KRAFormView';
import CompetencyView from './Competency/competencyView';
import { useSelector } from 'react-redux';
import { GoalFormFieldNames, GoalStatus } from '../Utlis/goals.util';
import { CreateGoal, GoalFormHeaders, PerformanceChild, ViewGoal } from './common/constants';
import { permissionsObj } from '../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';

export default function GolasView(props) {
  const { setViewMode, goalsConfig, kraConfig } = props;
  const { goalsFormData, kraFormData, competencyData
  } = useSelector((state) => state.goals);
  const { performancePermission } = useSelector((state) => state.modulePermissions);


  return (
    <Box>
      <Box>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            labelText='Goal'
            editButtonText='Edit'
            onEditClick={() => setViewMode(false)}
            showSave = {performancePermission?.[PerformanceChild]?.allowEdit && 
              goalsFormData[GoalFormFieldNames.GoalStatus]?.id !==GoalStatus.Activate && goalsFormData[GoalFormFieldNames.GoalStatus]?.id !==GoalStatus.Deactivated} 
          />
        </Box>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item md={8}>
            <Box
              py={2}
              px={3}
              bgcolor={'neutral.light80'}
              borderRadius={'8px 8px 0 0'}
            >
              <Typography variant='body2Bold' color={'neutral.dark80'}>
                Goal: {goalsFormData[GoalFormFieldNames.Name]}
              </Typography>
            </Box>
            <Paper sx={{ p: 3 }}>
              <FormView
                list={goalsConfig}
                info={goalsFormData}
              />
              <Box mt={3}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item md={12}>
                    {kraConfig.map((kraFormConfig, index) => (
                      <AccordionTabs
                        key={index}
                        title={`${kraFormConfig?.[0]?.fieldLabel} ${index + 1}`}
                        tabDetails={
                          <KRAFormView
                            formConfig={kraFormConfig}
                            formData={kraFormData[index]}
                            kraIndex={index}
                          />}
                      />

                    ))}
                    <AccordionTabs
                      title='Competency'
                      tabDetails={<CompetencyView data={competencyData}
                      />}
                    />
{/* 
                    <AccordionTabs
                      title='Skills'
                      tabDetails={<SkillsView />}
                    /> */}
                  </Grid>
                </Grid>
              </Box>

            </Paper>
          </Grid>
          {/* <Grid item md={4}>
            <Box
              ml={6}
              pl={6}
              sx={{ borderLeft: 'solid 1px', borderColor: 'neutral.dark40' }}
              mb={3}
            >
              <Typography variant='subtitle2' color={'neutral.dark80'} mb={3}>
                Audit Trail
              </Typography>

              <Box>
                <EventCard />
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
}
