import {
  APIRequestStatus,
  ComponentInputType,
  LoadingStatus,
} from '../../constants/common';
import {
  getFormattedStartOrEndDateForAnyPastMonthFromCurrent,
} from '../../utils/formatter';
import {
  GET_ALL_PAYROLL_FAILURE,
  GET_ALL_PAYROLL_REQUEST,
  GET_ALL_PAYROLL_RUN_DATES_FAILURE,
  GET_ALL_PAYROLL_RUN_DATES_REQUEST,
  GET_ALL_PAYROLL_RUN_DATES_SUCCESS,
  GET_ALL_PAYROLL_SUCCESS,
  GET_PAYROLL_DETAILS_FAILURE,
  SET_DATE_FILTER_PAYROLL,
} from './actionTypes';
import { UPDATE_PAYROLL_INFO_SUCCESS } from './actionTypes';
import { UPDATE_PAYROLL_INFO_FAILURE } from './actionTypes';
import { UPDATE_PAYROLL_INFO_REQUEST } from './actionTypes';
import { RESET_PAYROLL } from './actionTypes';
import { GET_PAYROLL_DETAILS_SUCCESS } from './actionTypes';
import { GET_PAYROLL_DETAILS_REQUEST } from './actionTypes';

const initialState = {
  requestProcessing: {
    [APIRequestStatus.GET_PAYROLL_DETAILS]: LoadingStatus.Loading,
    [APIRequestStatus.UPDATE_PAYROLL_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.GET_ALL_PAYROLL]: LoadingStatus.Loading,
    [APIRequestStatus.GET_ALL_PAYROLL_RUNS]: LoadingStatus.Loading,
  },
  additionComponents: [],
  deductionComponents: [],
  payrollList: [],
  payrollRunList:[],
  payrollIdPropertiesMap: {},
  base: {},
  stdHrs: 1,
  period: null,
  prlRegisterId: null,
  cpfEmployeeAmt: 0,
  cpfEmployerAmt: 0,
  prlStatusCode: null,
  employee: null,
  auditLogs: [],
  sdf: {},
  directFunds: [],
  cpfApplicable: false,
  payDate: null,
  overtimeInfo: {},
  otApplicable: false,
  totalTaxable: 0,
  dateFilter: [
    getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', true),
    getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', false),
  ],
  employeeDeductions: [],
  employerDeductions: [],
  tax: []
};
export const payrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYROLL_DETAILS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_PAYROLL_DETAILS]: LoadingStatus.Loading,
        },
      };
    }

    case GET_PAYROLL_DETAILS_SUCCESS: {
      const res = action.payload;
      const {
        allowances,
        base,
        stdHrs,
        period,
        prlRegisterId,
        cpfEmployeeAmt,
        cpfEmployerAmt,
        prlStatusCode,
        employee,
        auditLogs,
        sdf,
        directFunds,
        cpfApplicable,
        salaryTxnDate,
        otApplicable,
        overtimeInfo,
        totalTaxable,
        employeeDeductions,
        employerDeductions,
        tax
      } = res;
      const details = allowances.reduce(
        (accumulator, formInfo) => {
          if (formInfo.componentTxnTypeCode === 'A') {
            accumulator.additions.push(formInfo);
          } else {
            accumulator.deductions.push(formInfo);
          }
          return accumulator;
        },
        { additions: [], deductions: [] }
      );
      sortComponents(details.additions);
      sortComponents(details.deductions);
      return {
        ...state,
        additionComponents: details.additions,
        deductionComponents: details.deductions,
        base: base,
        stdHrs: stdHrs,
        period: period,
        prlRegisterId: prlRegisterId,
        cpfEmployeeAmt: cpfEmployeeAmt,
        cpfEmployerAmt: cpfEmployerAmt,
        prlStatusCode: prlStatusCode,
        employee: employee,
        auditLogs: auditLogs,
        sdf: sdf,
        payDate: salaryTxnDate,
        totalTaxable: totalTaxable,
        directFunds: cpfApplicable
          ? [
              ...directFunds,
              {
                name: 'Employee Contribution',
                value: cpfEmployeeAmt,
                // cpfWageClassificationCode: 'Based on AW/OW',
              },
            ]
          : directFunds,
        cpfApplicable: cpfApplicable,
        otApplicable: otApplicable,
        overtimeInfo: overtimeInfo,
        employeeDeductions: employeeDeductions?.EmployeeDeductionComponents,
        employerDeductions: employerDeductions?.EmployerDeductionComponents,
        tax: tax?.taxComponents,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_PAYROLL_DETAILS]: LoadingStatus.Success,
        },
      };
    }

    case GET_PAYROLL_DETAILS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_PAYROLL_DETAILS]: LoadingStatus.Failure,
        },
      };
    }

    case UPDATE_PAYROLL_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_PAYROLL_INFO]: LoadingStatus.Loading,
        },
      };
    }

    case UPDATE_PAYROLL_INFO_SUCCESS: {
      const { additionComponent, deductionComponent } = action.payload;
      return {
        ...state,
        additionComponents: additionComponent,
        deductionComponents: deductionComponent,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_PAYROLL_INFO]: LoadingStatus.Success,
        },
      };
    }

    case UPDATE_PAYROLL_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_PAYROLL_INFO]: LoadingStatus.Failure,
        },
      };
    }

    case GET_ALL_PAYROLL_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_PAYROLL]: LoadingStatus.Loading,
        },
      };
    }

    case GET_ALL_PAYROLL_SUCCESS: {
      const { list, idStatusMap } = action.payload;
      let payrollIdPropertiesMap = {};
      list?.forEach((item) => {
        Object.assign(payrollIdPropertiesMap, {
          [item.id]: {
            empId: item.timeManagement.employee.employeeId,
            period: item.timeManagement.period,
            status: item?.prlStatusCode?.payrollStatusCode,
          },
        });
      });

      return {
        ...state,
        payrollList: list,
        idStatusMap,
        payrollIdPropertiesMap: payrollIdPropertiesMap,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_PAYROLL]: LoadingStatus.Success,
        },
      };
    }

    case GET_ALL_PAYROLL_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_PAYROLL]: LoadingStatus.Failure,
        },
      };
    }

    case GET_ALL_PAYROLL_RUN_DATES_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_PAYROLL_RUNS]: LoadingStatus.Loading,
        },
      };
    }

    case GET_ALL_PAYROLL_RUN_DATES_SUCCESS: {
      return {
        ...state,
        payrollRunList:action.payload,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_PAYROLL_RUNS]: LoadingStatus.Success,
        },
      };
    }
  
    case GET_ALL_PAYROLL_RUN_DATES_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_PAYROLL_RUNS]: LoadingStatus.Failure,
        },
      };
    }
    case SET_DATE_FILTER_PAYROLL: {
      const date = action.payload;
      return {
        ...state,
        dateFilter: date,
      };
    }

    case RESET_PAYROLL: {
      return {
        initialState,
      };
    }

    default: {
      return state;
    }
  }
};

const sortComponents = (sortedArray) => {
  return sortedArray.sort((a, b) => {
    const sourceCodeA = a.inputSourceCode || '';
    const sourceCodeB = b.inputSourceCode || '';

    if (
      sourceCodeA === ComponentInputType.Onetime &&
      sourceCodeB !== ComponentInputType.Onetime
    ) {
      return -1;
    } else if (
      sourceCodeA !== ComponentInputType.Onetime &&
      sourceCodeB === ComponentInputType.Onetime
    ) {
      return 1;
    } else {
      return 0;
    }
  });
};