import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { FormHeader } from '../../../common/formHeader';
import ListView from '../../../../../atoms/DataList/ListView';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DebounceTimer, LoadingStatus, ToastThemes } from '../../../../../constants/common';
import { InfoOutlined } from '@mui/icons-material';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { searchQuery } from '../../../../../constants/constants';
import { permissionsObj } from '../../RolesAndPermissions/constants/roles-permissions.constants';
import { showToast } from '../../../../../utils/common.util';
import { IconButton } from '@mui/material';
import { salaryComponent } from '../../constants/onboarding.constants';
import AddSalaryComponent from '../salary-component-form/AddSalaryComp';
import { PayrollAssignment } from '../../../../../services/payrollAssignment';
import { useDebounce } from 'usehooks-ts';

const AllSalaryComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(-1);
  const [viewMode, setViewMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [salaryComponentId, setSalaryComponentId] = useState({});
  const [searchParams] = useSearchParams();
  const { currentUserRoleId } = useSelector((state) => state.main);
  const searchInput = searchParams?.get(searchQuery);
  const debouncedVal = useDebounce(searchInput, DebounceTimer);

  const { permissions } = useSelector((state) => state.onBoarding);

  useEffect(async () => {
    if (!showForm) {
      try {
        setLoading(LoadingStatus.Loading);
        const response = await new PayrollAssignment().getListAllComponent(
          currentUserRoleId, searchInput
        );
        setData(response);
        setLoading(LoadingStatus.Success);
      } catch (err) {
        setLoading(LoadingStatus.Failure);
        showToast(err?.response?.data?.message, ToastThemes.error);
        throw err?.response?.data?.message;
      }
    }
  }, [showForm, debouncedVal]);

  const onClickAddIcon = () => {
    setShowForm(true);
    setSalaryComponentId('');
    setViewMode(false);
  };

  const onDetailsClick = (id) => {
    setShowForm(true);
    setViewMode(true);
    setSalaryComponentId(id);
  };

  const rowData = data?.map((items) => {
    const {
      id,
      name,
      componentTxnTypeCode,
      inputSourceCode,
      isTaxable
    } = items;
    return {
      name: name,
      txnType: componentTxnTypeCode,
      inputSoruce: inputSourceCode, 
      taxable: String(isTaxable).charAt(0).toUpperCase() + String(isTaxable).slice(1),
      action: !permissions?.[salaryComponent]?.[
        permissionsObj.allowEdit
      ] ? (
        { hide: true }
      ) : (
        <IconButton
          onClick={() => onDetailsClick(id)}
          disabled={
            !permissions?.[salaryComponent]?.[permissionsObj.allowEdit]
          }
        >
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        hide: true,
        value: id,
      },
    };
  });

  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      {showForm ? (
        <AddSalaryComponent
          viewMode={viewMode}
          setViewMode={setViewMode}
          payCycleId={salaryComponentId}
          setShowForm={setShowForm}
          setPayCycleId={setSalaryComponentId}
        />
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <FormHeader title="Salary Component" />
          </Box>
          <ListView
            headCells={[
              { id: 'name', label: 'Name' },
              { id: 'txnType', label: 'Component Type' },
              { id: 'inputSoruce', label: 'Input Source' },
              { id: 'taxable', label: 'Is Taxable' },
            ]}
            rowsData={rowData || []}
            showSerialNo={true}
            showCheckbox={false}
            showAddIcon={permissions?.[salaryComponent]?.[permissionsObj.allowAdd]}
            onAddClick={() => onClickAddIcon()}
          />
        </Box>
      )}
    </>
  );
};

export default AllSalaryComponent;
