import React, { useEffect, useMemo, useState } from 'react';
import useDidMountEffect from '../../../../../../customHooks/useDidMountEffect';
import {
  getFormBody,
  getMandatoryFields,
  getValidationErrors,
} from '../../../utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';
import { Box } from '@mui/material';
import ActionHeader from '../../../ActionHeader/ActionHeader';
import FormEdit from '../../../../common/Form/FormEdit';
import { showToast } from '../../../../../../utils/common.util';
import { LoadingStatus, toastMessage, ToastThemes } from '../../../../../../constants/common';
import CircularLoader from '../../../../../../atoms/CircularLoader/circular-loader';
import { PayrollAssignment } from '../../../../../../services/payrollAssignment';
import { useSelector } from 'react-redux';
import {
  stringSubstitute,
  ToastMessages,
} from '../../../constants/onboarding.constants';

const PaycycleFormEdit = (props) => {
  const {
    setViewMode,
    formConfig,
    isNew,
    formData,
    setShowForm,
    payCycleId,
    setPayCycleId,
  } = props;

  const [validationErrors, setValidationErrors] = useState({});
  const [formInfo, setFormInfo] = useState({});
  const [loading, setLoading] = useState(-1);
  const [option1, setOption1] = useState([]);
  const [option2, setOption2] = useState([]);

  const [disabledSave, setDisabledSave] = useState(true);
  const { currentUserRoleId } = useSelector((state) => state.main);

  useEffect(() => {
    setFormInfo(formData);
  }, [formData]);

  useDidMountEffect(() => {
    setDisabledSave(false);
  }, [formInfo]);

  const handleDiscard = () => {
    if (isNew) {
      setShowForm(false);
    } else {
      setViewMode(true);
    }
  };

  const requiredFields = useMemo(() => {
    return getMandatoryFields(formConfig);
  }, [formConfig]);

  const handleSave = async () => {
    const errors = getValidationErrors(formInfo, requiredFields);
    if (isEmpty(errors)) {
      setLoading(LoadingStatus.Loading);
      const body = getFormBody(formInfo);
  
      try {
        const res = await new PayrollAssignment().postComponent(body, currentUserRoleId);
        setPayCycleId(res);
  
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
  
        const data = await new PayrollAssignment().getComponentDetail(res);
        setFormInfo(data);
  
        setViewMode(true);
        setLoading(LoadingStatus.Success);
      } catch (err) {
        showToast(err?.response?.data?.message || 'Something Went Wrong', ToastThemes.error);
        setLoading(LoadingStatus.Failure);
        console.error(err?.response?.data?.message || err);
        throw err?.response?.data?.message;
      }
    } else {
      setValidationErrors(errors);
    }
  };
  

  const handleDropdownClick = (name) => {
    switch (name) {
      case 'inputSourceCode': {
        return new PayrollAssignment()
          .getComponentDropDown('inputSourceCode')
          .then((res) => {
            setOption1(res);
            return true;
          });
      }
      case 'componentTxnTypeCode': {
        return new PayrollAssignment()
          .getComponentDropDown('componentTxnTypeCode')
          .then((res) => {
            setOption2(res);
            return true;
          });
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case 'inputSourceCode': {
        return option1;
      }
      case 'componentTxnTypeCode': {
        return option2;
      }
    }
  };

  return (
    <Box>
      <CircularLoader show={loading === LoadingStatus.Loading && payCycleId} />
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={formData?.name || 'Salary Component'}
          showDiscard
          showPublish
          showPublishModal={false}
          publishButtonText="Save"
          onPublishClick={handleSave}
          onClickDiscard={handleDiscard}
          showSave={false}
          disabledPublish={disabledSave}
        />
      </Box>
      <FormEdit
        list={formConfig}
        formInfo={formInfo}
        setFormData={setFormInfo}
        errors={validationErrors}
        setErrors={setValidationErrors}
        handleDropdownClick={handleDropdownClick}
        getDropdownOptions={getDropdownOptions}
      />
    </Box>
  );
};

export default PaycycleFormEdit;
