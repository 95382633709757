import React from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import SelfieAndLocationForm from '../AttendanceForm/SelfieAndLocation/SelfieAndLocationForm';
import { Box } from '@mui/material';
import TabBar from '../../../atoms/Tabs/TabBar';
import TryAgain from '../../molecules/ErrorState/error.component';
import ManageLeaveFormTab from '../../leave-management/leave-form/manage-leave-form/manage-leave-form.component';

const TimeManagementFormTab = () => {
  const [viewMode, setViewMode] = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const tabId = pathNames[pathNames.length - 2];

  const getTabs = () => {
    if (tabId === 'attendance') {
      const tabs = [
        {
          name: 'attendance',
          label: 'Attendance Form',
          title: 'Attendance Form',
          hide: false,
          panel: (
            <SelfieAndLocationForm
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
          ),
        },
      ];
      return <TabBar tabPanels={tabs} disableCreate />;
    }

    else if(!isNaN(Number(tabId))){
      return <ManageLeaveFormTab />;
    }

    navigate('/home');
    return <TryAgain />; 
  };

  return <Box mt={4}>{getTabs()}</Box>;
};

export default TimeManagementFormTab;
